import { QEValConstants } from '../../../common/QEValConstants';
import { tokenize_and_parse, findGrammar } from '../../../common/QEGrammar';
import { QETerm } from '../../../common/QETerm';
import { QESolverStep } from '../QESolverStep';
import { QEValue, QEValueMap, QEValueTree } from '../../../common/QEHelper';
import { QESolver, SolverStepOutput } from '../../QESolver';
import {
//	comparisonResult,
	multiplicativeTermToList,
	listToMultiplicativeTerm,
	createDividedTerm,
//	cancelIdenticalFracTerms,
//	divideDecimalsInFracTerms,
//	factorOutCommonFracFactors,
//	parseToChainModeTerm,
	additiveChainToList,
	listToAdditiveChain,
	getCharacterizedTermFactors,
	sortMultiplicativeTermList,
	sortAdditiveTermList,
	helper_fractionDenominatorsLCM,
	SimplePolygon,
} from "../SolverUtils";

export class Convert {
	static convertDenominatorToCondenseWords(numString: string, denString: string): string {
		// convert denominator to a fraction-word
		if (denString.length > 3) {
			return undefined;
		}

		const num = parseInt(numString);
		const den = parseInt(denString);
		let words = Convert.convertThreeDigitsToWords(den, true);

		// special cases for ones and halves
		if (den == 1) {
			if (num > 1) words = 'wholes';
			else words = 'whole';
		} else if (den == 2) {
			if (num > 1) words = 'halves';
			else words = 'half';
		} else {
			if (num > 1) {
				words += "s";
			}
		}

		return words;
	}

	static convertDecimalToCondensedWords(numberString: string): string {
		if (numberString.length > 3 || numberString.length == 0) {
			return undefined;
		}

		const number = parseInt(numberString);
		let words = Convert.convertIntNumberToWord(number);

		if (numberString.length == 3) {
			words += " thousandth";
		} else if (numberString.length == 2) {
			words += " hundredth";
		} else if (numberString.length == 1) {
			words += " tenth";
		}

		if (number > 1) {
			words += "s";
		}

		return words;
	}

	static convertWholeNumberToRoman(input_value: QEValueTree, options: any): any {
		if (input_value.type != "tree") return undefined;
		const eq = input_value.value;

		// check the eq contains only a RATIONAL decimal
		const old_term = eq.children[0];
		if (old_term.type != "RATIONAL") return undefined;
		if (old_term.attr("value_type") != "decimal" && old_term.attr("value_type") != "integer") return undefined;

		let number = Number(old_term.serialize_to_text());

		// cap at 1 - 3999
		if (number < 1 || number > 3999 || !Number.isInteger(number)) {
			return; // Error - only whole numbers from 1 to 3999 can be expressed as Roman numerals.
		}

		let roman = '';
		const place_values = [
			{ value: 1000, char: 'M' },
			{ value: 100, char: 'C', quint_char: 'D' },
			{ value: 10, char: 'X', quint_char: 'L' },
			{ value: 1, char: 'I', quint_char: 'V' },
		];
		// iterate over place values
		for (let i = 0; i < place_values.length; i++) {
			let place = place_values[i];

			let divisor = Math.floor(number / place.value);
			if (divisor > 0 && divisor <= 3) {
				roman += place.char.repeat(divisor);
			} else if (divisor == 4) {
				roman += place.char + place.quint_char;
			} else if (divisor >= 5 && divisor <= 8) {
				roman += place.quint_char + place.char.repeat(divisor - 5);
			} else if (divisor == 9) {
				roman +=  place.char + place_values[i - 1].char;
			}
			number -= divisor * place.value;
		}

		return {
			desc: roman,
			value: roman,
			type: "string",
		};
	}

	static convertIntNumberToWord(number: number): string {
		const map = ["", "thousand", "million"];
		const wordsArray = [];
		let rest = number;
		let index = 0;
		do {
			const convertingNumber = rest % 1000;
			const converted = Convert.convertThreeDigitsToWords(convertingNumber);
			if (converted.length > 0) {
				if (map[index].length > 0) {
					wordsArray.unshift(map[index]);
				}
				wordsArray.unshift(converted);
			}
			rest = Math.floor(rest / 1000);
			index++;
		} while (rest > 0);
		return wordsArray.join(" ");
	}

	static convertThreeDigitsToWords(number: number, isOneOrdinal = false): string {
		const hundred = Math.floor(number / 100);
		const rest = number % 100;
		const restString = rest.toString();

		const cardinalMap = QEValConstants.string_maps["number"]["cardinal"];
		const ordinalMap = QEValConstants.string_maps["number"]["ordinal"];

		const wordsArray = [];
		if (hundred != 0) {
			wordsArray.push(cardinalMap[hundred.toString()]);
			wordsArray.push("hundred");
		}
		if (rest == 0) {
			return wordsArray.join(" ");
		} else if (cardinalMap[restString] !== undefined) {
			if (isOneOrdinal) {
				wordsArray.push(ordinalMap[restString]);
			} else {
				wordsArray.push(cardinalMap[restString]);
			}
			return wordsArray.join(" ");
		} else {
			const ten = Math.floor(rest / 10) * 10;
			const one = rest % 10;

			const tenString = ten != 0 ? cardinalMap[ten.toString()] : "";
			const oneString = one != 0 ? (isOneOrdinal ? ordinalMap[one.toString()] : cardinalMap[one.toString()]) : "";
			if (ten != 0 && one != 0) {
				wordsArray.push(tenString + "-" + oneString);
			} else if (ten != 0) {
				wordsArray.push(tenString);
			} else if (one != 0) {
				wordsArray.push(oneString);
			}

			return wordsArray.join(" ");
		}
	}

	static convertFractionToWords(input_value: QEValueTree, options: any): any {
		if (input_value.type != "tree") return undefined;
		const eq = input_value.value;

		// check the eq contains only a RATIONAL decimal
		const old_term = eq.children[0];
		if (old_term.type != "FUNCTION") return undefined;
		if (old_term.value != "frac") return undefined;

		// perform the conversion
		const num = old_term.children[0].value;
		const den = old_term.children[1].value;

		const numWords = Convert.convertIntNumberToWord(parseInt(num));
		const denWords = Convert.convertDenominatorToCondenseWords(num, den);

		let words = "";
		if (numWords.includes("-") || denWords.includes("-")) {
			words = numWords + " " + denWords;
		} else {
			words = numWords + "-" + denWords;
		}

		return {
			desc: words,
			value: words,
			type: "string",
		};
	}

	static convertNumberToWords(input_value: QEValueTree, options: any): any {
		if (input_value.type != "tree") return undefined;
		const eq = input_value.value;

		// check the eq contains only a RATIONAL decimal
		const old_term = eq.children[0];

		if (old_term.type != "RATIONAL") return undefined;
		if (old_term.attr("value_type") != "decimal" && old_term.attr("value_type") != "integer") return undefined;

		// the following is only needed for description purposes
		const Q = old_term.Q;
		const parts = Q.serialize_to_decimal_parts();
		const integer = parts[0];
		const decimal = parts[1];
		const repeating = parts[2];

		// no easy way to get the fraction from a number with repeating decimals
		if (repeating) {
			return;
		}

		let words = "";

		if (Number(integer) != 0) {
			words += Convert.convertIntNumberToWord(Number(integer));
		}

		if (Number(decimal) != 0) {
			if (words.length > 0) {
				words += " and ";
			}
			const decimalWords = Convert.convertDecimalToCondensedWords(decimal);
			if (decimalWords.length > 0) {
				words += decimalWords;
			}
		}

		if (Number(integer) == 0 && Number(decimal) == 0) {
			const cardinalMap = QEValConstants.string_maps["number"]["cardinal"];
			words += cardinalMap[integer];
		}

		return {
			desc: words,
			value: words,
			type: "string",
		};
	}

	static convertDecimalToFraction(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;
		const eq = input_value.value;

		// check the eq contains only a RATIONAL decimal
		const old_term = eq.children[0];
		if (old_term.type != "RATIONAL") return undefined;
		if (old_term.attr("value_type") != "decimal") return undefined;

		const old_term_q = old_term.evaluate_to_Q();
		if (typeof old_term_q == "number") {
			console.log("Error: old_term was NaN");
			return undefined;
		}
		const new_term = old_term_q.to_term({ value_type: "fraction" });

		// the following is only needed for description purposes
		const Q = old_term.Q;
		const parts = Q.serialize_to_decimal_parts();
		const integer = parts[0];
		const decimal = parts[1];
		const repeating = parts[2];

		// no easy way to get the fraction from a number with repeating decimals
		if (repeating) {
			return;
		}
		// nothing to do if the number doesn't have decimals
		if (!decimal) {
			return;
		}

		const num_str = Math.trunc(Number(integer + decimal)).toString();
		const den_str = "1" + Array(decimal.length + 1).join("0");
		const new_frac = QETerm.create({ type: "FUNCTION", value: "frac" });
		new_frac.pushChild(QETerm.create({ type: "RATIONAL", value: num_str }));
		new_frac.pushChild(QETerm.create({ type: "RATIONAL", value: den_str }));

		let desc = "To convert a decimal number to a fraction:";
		desc += "<ol>";
		desc += "<li>Remove the decimal point. It's like multiplying by 10 for each digit on the right side of the decimal point.</li>";
		desc += "<li>To preserve the original value, divide by the same amount. (" + den_str + ")</li>";
		desc += "<li>The denominator has one zero for each decimal digit.<br>";
		desc += old_term.display() + " = " + new_frac.display();
		desc += "</li>";
		if (Number(den_str) != new_term.Q.den) {
			// reduce fraction
			desc += "<li>As a last step, simplify the fraction.<br>";
			desc += new_frac.display() + " = " + new_term.display();
			desc += "</li>";
		}
		desc += "</ol>";

		return {
			desc: desc,
			old_term: old_term,
			new_term: new_term,
			type: "tree",
		};
	}
	static convertDecimalToPercent(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;
		const eq = input_value.value;

		// check the eq contains only a RATIONAL decimal
		const old_term = eq.children[0];
		if (old_term.type != "RATIONAL") return undefined;
		if (old_term.attr("value_type") != "decimal") return undefined;

		// perform the conversion
		const Q = old_term.evaluate_to_Q();
		if (typeof Q == "number") {
			console.log("Error: old_term was NaN");
			return undefined;
		}
		const percent_term = Q.to_term({ value_type: "percent" });

		// The steps below are only needed for the description
		const hundred_percent = QETerm.create({ type: "RATIONAL", value: "100%" });
		hundred_percent.attr("value_type", "percent");

		const multiply_term = QETerm.create({ type: "CHAIN", precedence: findGrammar("MULTIPLY").precedence });
		multiply_term.pushChild(old_term.clone());
		multiply_term.pushChild(QETerm.create({ type: "MULTIPLY" }));
		multiply_term.pushChild(hundred_percent);

		let desc = "To convert a decimal number to a percent, simply multiply by 100:<br>";
		desc += multiply_term.display() + " = " + percent_term.display();

		return {
			desc: desc,
			old_term: old_term,
			new_term: percent_term,
			type: "tree",
			hide_transformation: true,
		};
	}
	static convertFractionToDecimal(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;
		const eq = input_value.value;

		// check the eq contains a fraction with rational numerator and denominator
		const old_term = eq.children[0];
		if (old_term.value != "frac") return undefined;
		if (old_term.children[0].type != "RATIONAL") return undefined;
		if (old_term.children[1].type != "RATIONAL") return undefined;

		// perform the conversion
		const Q = old_term.evaluate_to_Q();
		if (typeof Q == "number") {
			console.log("Error: old_term was NaN");
			return undefined;
		}
		const decimal_term = Q.to_term({ value_type: "decimal" });

		const desc = "To convert a fraction to a decimal number, divide the numerator of the fraction by the denominator.";

		return {
			desc: desc,
			old_term: old_term,
			new_term: decimal_term,
			type: "tree",
		};
	}
	static convertFractionToPercent(input_value, options) {
		if (input_value.type != "tree") return undefined;
		const eq = input_value.value;

		// check the eq contains a fraction with rational numerator and denominator
		const old_term = eq.children[0];
		if (old_term.value != "frac") return undefined;
		if (old_term.children[0].type != "RATIONAL") return undefined;
		if (old_term.children[1].type != "RATIONAL") return undefined;

		// perform the conversion
		const Q = old_term.evaluate_to_Q();
		if (typeof Q == "number") {
			console.log("Error: old_term was NaN");
			return undefined;
		}
		const decimal_term = Q.to_term({ value_type: "decimal" });
		const percent_term = Q.to_term({ value_type: "percent" });

		// The steps below are only needed for the description
		const hundred_percent = QETerm.create({ type: "RATIONAL", value: "100%" });
		hundred_percent.attr("value_type", "percent");

		const multiply_term = QETerm.create({ type: "CHAIN", precedence: findGrammar("MULTIPLY").precedence });
		multiply_term.pushChild(decimal_term);
		multiply_term.pushChild(QETerm.create({ type: "MULTIPLY" }));
		multiply_term.pushChild(hundred_percent);

		let desc = "To convert a fraction to a percent:";
		desc += "<ol>";
		desc += "<li>Convert the fraction to a decimal number, by dividing the numerator of the fraction by the denominator:<br>";
		desc += old_term.display() + " = " + decimal_term.display();
		desc += "</li>";
		desc += "<li>Multiply by 100 to convert the decimal number into a percent:<br>";
		desc += multiply_term.display() + " = " + percent_term.display();
		desc += "</li>";

		return {
			desc: desc,
			old_term: old_term,
			new_term: percent_term,
			type: "tree",
		};
	}

	static convertPercentToFraction(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;
		const eq = input_value.value;

		// check the eq contains only a RATIONAL percent
		const old_term = eq.children[0];
		if (old_term.type != "RATIONAL") return undefined;
		if (old_term.attr("value_type") != "percent") return undefined;

		const Q = old_term.Q;
		if (typeof Q == "number") {
			console.log("Error: old_term was NaN");
			return undefined;
		}
		const new_term = Q.to_term({ value_type: "fraction" });

		// the following is only needed for description purposes
		const parts = Q.serialize_to_decimal_parts();
		const integer = parts[0];
		const decimal = parts[1];
		const repeating = parts[2];

		// no easy way to get the fraction from a number with repeating decimals
		if (repeating) {
			return;
		}
		if (!decimal) {
			return;
		}

		const num_str = parseInt(integer + decimal).toString();
		const den_str = "1" + Array(decimal.length + 1).join("0");
		const new_frac = QETerm.create({ type: "FUNCTION", value: "frac" });
		new_frac.pushChild(QETerm.create({ type: "RATIONAL", value: num_str }));
		new_frac.pushChild(QETerm.create({ type: "RATIONAL", value: den_str }));

		let desc = "To convert a percent to a fraction:";
		desc += "<ol>";
		desc += "<li>First convert the percent into a decimal by dividing by 100:<br>";
		desc += old_term.display() + " = " + old_term.Q.to_term({ value_type: "decimal" }).display();
		desc += "</li>";
		desc += "<li>Remove the decimal point. It's like multiplying by 10 for each digit on the right side of the decimal point.</li>";
		desc += "<li>To preserve the original value, divide by the same amount. (" + den_str + ")</li>";
		desc += "<li>The denominator has one zero for each decimal digit.<br>";
		desc += old_term.Q.to_term({ value_type: "decimal" }).display() + " = " + new_frac.display();
		desc += "</li>";
		if (Number(den_str) != new_term.Q.den) {
			// reduce fraction
			desc += "<li>As a last step, simplify the fraction.<br>";
			desc += new_frac.display() + " = " + new_term.display();
			desc += "</li>";
		}
		desc += "</ol>";

		return {
			desc: desc,
			old_term: old_term,
			new_term: new_term,
			type: "tree",
		};
	}

	static convertPercentToDecimal(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;
		const eq = input_value.value;

		// check the eq contains only a RATIONAL decimal
		const old_term = eq.children[0];
		if (old_term.type != "RATIONAL") return undefined;
		if (old_term.attr("value_type") != "percent") return undefined;

		// perform the conversion
		const Q = old_term.evaluate_to_Q();
		if (typeof Q == "number") {
			console.log("Error: old_term was NaN");
			return undefined;
		}
		const decimal_term = Q.to_term({ value_type: "decimal" });

		// The steps below are only needed for the description
		const hundred_percent = QETerm.create({ type: "RATIONAL", value: "100%" });
		hundred_percent.attr("value_type", "percent");

		const divide_term = QETerm.create({ type: "CHAIN", precedence: findGrammar("MULTIPLY").precedence });
		divide_term.pushChild(old_term.clone());
		divide_term.pushChild(QETerm.create({ type: "DIVIDE" }));
		divide_term.pushChild(hundred_percent);

		let desc = "To convert a percent to a decimal number, simply divide by 100:<br>";
		desc += divide_term.display() + " = " + decimal_term.display();

		return {
			desc: desc,
			old_term: old_term,
			new_term: decimal_term,
			type: "tree",
			hide_transformation: true,
		};
	}

	/**
	 * convertImperialLengthUnit - Converts a value from the specified source imperial unit to the specified destination imperial unit
	 */
	static convertImperialLengthUnit(input_value: QEValueMap, options: any): SolverStepOutput {
		if (input_value.type != "map") return undefined;
		const params = input_value.value;

		if (!params["src_value"] || !params["src_unit"] || !params["dest_unit"]) {
			return undefined;
		}

		// validate src_unit and dest_unit
		const imperial_length_units = ["in", "ft", "yd", "mi"];
		const src_unit = params["src_unit"].serialize_to_text();
		if (imperial_length_units.indexOf(src_unit) == -1) {
			return undefined;
		}
		const dest_unit = params["dest_unit"].serialize_to_text();
		if (imperial_length_units.indexOf(dest_unit) == -1) {
			return undefined;
		}

		// validate src_value
		let src_value = params["src_value"];
		if (src_value.type != "tree" || src_value.value.children[0].type != "RATIONAL") {
			console.log('Error: expected QEValueTree but got: ', src_value);
			return undefined;
		}
		src_value = src_value.value.children[0];

		const units_in_inch = { 
			in: 1,
			ft: 12,
			yd: 36,
			mi: 63360,
		};

		const conversion_factor = QETerm.create({ type: "RATIONAL", value: units_in_inch[src_unit] + "/" + units_in_inch[dest_unit] });

		const new_value = src_value.Q.multiply(conversion_factor.Q);
		const new_term = new_value.to_term({ value_type: "mixed" });

		// TODO: include unit conversion table in solution
		let desc = "";
		desc += '<div class="sub-step">1 ' + src_unit + " = " + conversion_factor.display() + " " + dest_unit + "</div>";

		const mult = QETerm.create({ type: "CHAIN", precedence: findGrammar("MULTIPLY").precedence });
		mult.pushChild(src_value);
		mult.pushChild(QETerm.create({ type: "MULTIPLY" }));
		mult.pushChild(conversion_factor);

		desc += '<div class="sub-step">' + mult.display() + " = " + new_term.display() + " " + dest_unit + "</div>";

		const result = {
			desc: desc,
			value: new_term,
			type: "tree",
		};

		return result;
	}
	/**
	 * convertMetricLengthUnit - Converts a value from the specified source metric unit to the specified destination metric unit
	 */
	static convertMetricLengthUnit(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "map") return undefined;
		const params = input_value.value;

		if (!params["src_value"] || !params["src_unit"] || !params["dest_unit"]) {
			return undefined;
		}

		// validate src_unit and dest_unit
		const metric_length_units = ["mm", "cm", "m", "km"];
		const src_unit = params["src_unit"].serialize_to_text();
		if (metric_length_units.indexOf(src_unit) == -1) {
			return undefined;
		}
		const dest_unit = params["dest_unit"].serialize_to_text();
		if (metric_length_units.indexOf(dest_unit) == -1) {
			return undefined;
		}

		// validate src_value
		let src_value = params["src_value"];
		if (src_value.type != "tree" || src_value.value.children[0].type != "RATIONAL") {
			console.log('Error: expected QEValueTree but got: ', src_value);
			return undefined;
		}
		src_value = src_value.value.children[0];

		const units_in_mm = {
			mm: 1,
			cm: 10,
			m: 1000,
			km: 1000000,
		};

		const conversion_factor = QETerm.create({ type: "RATIONAL", value: (units_in_mm[src_unit] / units_in_mm[dest_unit]).toString() });

		const new_value = src_value.Q.multiply(conversion_factor.Q);
		const new_term = new_value.to_term({ value_type: "decimal" });

		// TODO: include unit conversion table in solution
		let desc = "";
		desc += '<div class="sub-step">1 ' + src_unit + " = " + conversion_factor.display() + " " + dest_unit + "</div>";

		const mult = QETerm.create({ type: "CHAIN", precedence: findGrammar("MULTIPLY").precedence });
		mult.pushChild(src_value);
		mult.pushChild(QETerm.create({ type: "MULTIPLY" }));
		mult.pushChild(conversion_factor);

		desc += '<div class="sub-step">' + mult.display() + " = " + new_term.display() + " " + dest_unit + "</div>";

		const result = {
			desc: desc,
			value: new_term,
			type: "tree",
		};

		return result;
	}

	/**
	 * convertToChains - Converts a binary op-tree to a tree of CHAIN operators
	 */
	static convertToChains(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;
		if (root.children == undefined || root.children.length === 0) {
			return undefined;
		}

		// check that tree has BINARY ops that can be converted to CHAINs
		const binary_ops = root.findAllChildren(function (term) {
			const grammar = findGrammar(term.type);

			return (
				(grammar.precedence === findGrammar("EQUAL").precedence ||
					grammar.precedence === findGrammar("ADD").precedence ||
					grammar.precedence === findGrammar("MULTIPLY").precedence) &&
				term.children.length == 2
			);
		});
		if (!binary_ops.length) {
			return undefined;
		}

		const clone = root.clone();
		clone.convertBinaryOpsToChains();

		return {
			desc: "",
			old_term: root.children[0],
			new_term: clone.children[0],
			type: "tree",
			hide_step: true,
		};
	}

	static convertFractionsToSameDenominator(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;

		// Validate tree contains a list with at least 2 items
		if (input_value.value.children[0].type != "FUNCTION" || input_value.value.children[0].value != "list" || input_value.value.children[0].children.length != 2) {
			return undefined;
		}
		const old_term = input_value.value.children[0];
		const terms = input_value.value.children[0].children;

		const vars = old_term.findAllChildren("type", "VARIABLE");
		// Don't support fraction variable comparison
		if (vars.length > 0) {
			return undefined;
		}

		const denLcm = helper_fractionDenominatorsLCM(input_value, options);
		// Can't find lesat common multiple for denominator, skip.
		if (denLcm == undefined) {
			return undefined;
		}

		const isTermWithSameDen = function (term, den) {
			return term.value == "frac" && term.children[1].value == den;
		};
		// Fractions are the same denominator, skip.
		if (terms.filter((term) => isTermWithSameDen(term, denLcm)).length == terms.length) {
			return undefined;
		}

		const chain = [];
		for (let j = 0; j < terms.length; j++) {
			if (terms[j].value == "frac") {
				const denominator = terms[j].children[1].value;
				const numerator = (parseInt(terms[j].children[0].value) * denLcm) / parseInt(denominator);

				const frac = QETerm.create({ type: "FUNCTION", value: "frac" });
				frac.pushChild(QETerm.create({ type: "RATIONAL", value: numerator.toString() }));
				frac.pushChild(QETerm.create({ type: "RATIONAL", value: denLcm.toString() }));
				chain.push(frac);
			} else if (terms[j].type == "RATIONAL") {
				const numerator = parseInt(terms[j].value) * denLcm;

				const frac = QETerm.create({ type: "FUNCTION", value: "frac" });
				frac.pushChild(QETerm.create({ type: "RATIONAL", value: numerator.toString() }));
				frac.pushChild(QETerm.create({ type: "RATIONAL", value: denLcm.toString() }));
				chain.push(frac);
			}
		}

		const converted_frac_list = tokenize_and_parse(
			"list{" +
				chain
					.map(function (x) {
						return x.serialize_to_text();
					})
					.join(",") +
				"}",
			{ merge_sign_operators: true }
		).tree.children[0];

		let desc = "";
		desc += 'The least common multiple of denominators is: <span style="font-weight: bold;">' + denLcm + ".</span>";
		desc += " Convert fractions by using common denominator<br><br>";

		const result = {
			desc: desc,
			old_term: old_term,
			new_term: converted_frac_list,
			type: "tree",
		};
		return result;
	}

	// roundNumberToNumPlaces: round the number to the 10s exponent place value (e.g. "2" -> hundreds, "-1" -> tenths, "0" -> ones)
	static roundNumberToNumPlaces(input_value, options): SolverStepOutput {
		if (input_value.type != "map") return undefined;
		var params = input_value.value;

		if (!params["number"] || !params["place"]) {
			return undefined;
		}

		let number = params["number"].serialize_to_text();
		let place = Number(params["place"].serialize_to_text());

		if (Number.isNaN(Number(number))) {
			console.log('roundNumberToNumPlaces "number" param is not a number');
			return undefined;
		} else if (!Number.isInteger(place)) {
			console.log('roundNumberToNumPlaces "places" param is not an integer');
			return undefined;
		}

		let sign = Number(number) < 0 ? -1 : 1;
		let num_str = number.replace(/^-/, '');

		var place_strings = {
			0: "one",
			1: "ten",
			2: "hundred",
			3: "thousand",
			6: "million",
			9: "billion",
			12: "trillion",
			15: "quadrillion",
		};
		let place_name = '';
		let place_base = Math.abs(place);
		if (place_base >= 18) {
			return undefined;
		}
		if (place_strings[place_base]) {
			place_name = place_strings[place_base];
		} else {
			place_name = place_strings[place_base % 3] + '-' + place_strings[place_base - place_base % 3];
		}
		if (place < 0) place_name += "ths";
		else place_name += "s";

		let desc = "";
		desc += "<div>First, find the digit in the "+ place_name +" place: ";

		let all_digits, dec_offset, rounded_value;
		if (num_str.match(/e/)) {
			// scientific-ish "e" notation, e.g. 0.0000001234 -> "1.234e-7"
			all_digits = num_str.split('e')[0].replace('.', '').split('');
			dec_offset = 1 + Number(num_str.split('e')[1]);

			// trim trailing zeroes, but only down to number of decimal place values
			while (place < 0 && (all_digits.length - dec_offset) > Math.abs(place) && all_digits[all_digits.length - 1] == "0"){
				all_digits.splice(all_digits.length - 1, 1);
			}
		} else {
			// long form, e.g. "12345.56"
			let int_part = num_str;
			let dec_part = '';
			if (num_str.match(/\./)) {
				int_part = num_str.split(/\./)[0];
				dec_part = num_str.split(/\./)[1];
			}

			let int_digits = int_part.split('');
			let dec_digits = dec_part.split('');

			// trim trailing zeroes from dec_digits, but only down to number of decimal place values
			while (place < 0 && dec_digits.length > Math.abs(place) && dec_digits[dec_digits.length - 1] == "0"){
				dec_digits.splice(dec_digits.length - 1, 1);
			}

			all_digits = [].concat(int_digits, dec_digits);
			dec_offset = int_digits.length;
		}

		// TODO: generate the description for each of the below cases. If negative, mention we're rounding away from zero for negative numbers
		if (dec_offset - place >= all_digits.length) {
			// place value smaller than number of decimal places

			// add decimal point if needed
			if (dec_offset < all_digits.length) all_digits.splice(dec_offset, 0, '.');

			rounded_value = all_digits.join('');
			if (rounded_value && sign < 0) rounded_value = '-'+ rounded_value; // don't apply sign to zero, it looks dumb
		} else if (dec_offset - place < -1) {
			// place value greater than number
			rounded_value = "0";
		} else {
			let place_digit = all_digits[dec_offset - place];
			if (Number(place_digit) >= 5) {
				// rounding up!

				// set following digits to zero
				for (let i = dec_offset - place + 1; i < all_digits.length; i++) {
					all_digits[i] = "0";
				}

				all_digits[dec_offset - place] = "10";

				// carry the one
				for (let i = dec_offset - place; i >= 0; i--) {
					if (Number(all_digits[i]) > 9) {
						if (!i) {
							// at start of number; set to zero and prepend a 1
							all_digits[i] = "0";
							all_digits.splice(0, 0, "1");
							dec_offset++; // move decimal point to the right one spot
						} else {
							// greater than 10: set to zero and increment preceding
							all_digits[i] = "0";
							all_digits[i - 1] = (Number(all_digits[i - 1]) + 1).toString();
						}
					}
				}

				// trim trailing zeroes from decimal portion, but only down to place
				while (place < 0 && (all_digits.length - dec_offset) > Math.abs(place) && all_digits[all_digits.length - 1] == "0"){
					all_digits.splice(all_digits.length - 1, 1);
				}
			} else {
				// set place_digit and following digits to zero
				for (let i = dec_offset - place; i < all_digits.length; i++) {
					all_digits[i] = "0";
				}

				// trim trailing zeroes from decimal portion, but only down to place
				while (place < 0 && (all_digits.length - dec_offset) > Math.abs(place) && all_digits[all_digits.length - 1] == "0"){
					all_digits.splice(all_digits.length - 1, 1);
				}
			}
			// add decimal point
			if (dec_offset < all_digits.length) all_digits.splice(dec_offset, 0, '.');

			rounded_value = all_digits.join('');
			if (rounded_value && sign < 0) rounded_value = '-'+ rounded_value; // don't apply sign to zero, it looks dumb
		}

		return {
			desc: desc,
			value: QETerm.create({ type: "RATIONAL", value: rounded_value }),
			type: "tree",
		};
	}

	/**
	 * convertMixedRadicalToEntire - Moves the coefficient(s) of a radical to within the radical
	 */
	static convertMixedRadicalToEntire(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;
		if (root.children == undefined || root.children.length === 0) {
			return undefined;
		}

		// find all radicals that are part of a multiply chain also containing rationals
		const radicals = root.findAllChildren(function (term) {
			if (['sqrt', 'nroot'].indexOf(term.value) == -1) return false;

			// check for coefficients
			if (!term.parent.isMultiplyChain()) return false;

			// check for any rational siblings in multiply chain
			const list = multiplicativeTermToList(term.parent);

			for (let i = 0; i < list.length; i++) {
				if (list[i].type == 'RATIONAL') return true;
			}

			return false;
		});

		for (let i = 0; i < radicals.length; i++) {
			const radical = radicals[i];
			let radical_val;
			let radical_root;

			let desc;
			if (radical.value == 'sqrt') {
				radical_root = QETerm.create({ type: "RATIONAL", value: "2" });
				radical_val = radical.children[0];
				desc = 'Move coefficients into the radical using the relationship <katex>a \\cdot \\sqrt{b}=\\sqrt{b \\cdot a^2}</katex>';
			} else {
				radical_root = radical.children[0];
				radical_val = radical.children[1];
				desc = 'Move coefficients into the radical using the relationship <katex>a \\cdot \\sqrt[c]{b}=\\sqrt{b \\cdot a^{c}}</katex>';
			}

			const parent_list: QETerm[] = multiplicativeTermToList(radical.parent);
			const radical_val_list = multiplicativeTermToList(radical_val);

			// move each RATIONAL sibling term into the radical, turning each into a power with exponent equal to the radical root
			for (let j= 0; j < parent_list.length; j++) {
				if (parent_list[j].type == 'RATIONAL') {
					const coeff = parent_list.splice(j, 1)[0];
					j--; // decrement the counter, since we just removed the current item

					// create new power with exponent of radical_root
					const new_val = QETerm.create({ type: "FUNCTION", value: "pow" });
					new_val.pushChild(coeff.clone());
					new_val.pushChild(radical_root.clone());

					radical_val_list.push(new_val);

					// highlight coeff and new term
					coeff.addClass("highlight_input");
					new_val.addClass("highlight_output");
				}
			}

			// build new radical value
			const new_radical_val = listToMultiplicativeTerm(radical_val_list);

			let new_radical;
			if (radical.value == 'sqrt') {
				new_radical = QETerm.create({ type: "FUNCTION", value: "sqrt" });
			} else {
				new_radical = QETerm.create({ type: "FUNCTION", value: "nroot" });
				new_radical.pushChild(radical_root.clone());
			}
			new_radical.pushChild(new_radical_val);

			// build new radical parent
			const new_parent_list = parent_list.map(function(term){
				if (term === radical) {
					return new_radical;
				} else {
					return term;
				}
			});
			const new_parent = listToMultiplicativeTerm(new_parent_list);

			// retain sign
			new_parent.sign = radical.parent.sign;

			return {
				desc: desc,
				old_term: radical.parent,
				new_term: new_parent,
				type: "tree",
			};
		}
	}

	/**
	 * convertRadicalToFractionalExponent - TODO
	 */
	static convertRadicalToFractionalExponent(input_value: QEValueTree, options: any): SolverStepOutput {
/*
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;
		if (root.children == undefined || root.children.length === 0) {
			return undefined;
		}
*/
		// TODO: stubbed: implement
		return undefined;
	}

	/**
	 * convertFractionalExponentToRadical - TODO
	 */
	static convertFractionalExponentToRadical(input_value: QEValueTree, options: any): SolverStepOutput {
/*
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;
		if (root.children == undefined || root.children.length === 0) {
			return undefined;
		}
*/
		// TODO: stubbed: implement
		return undefined;
	}

	static substituteVariableValues(input_value, options) {
		if (input_value.type != "map") return undefined;
		var params = input_value.value;

		if (!params["src"]) {
			return undefined;
		}

		var tree = params["src"].value;
		if (params["src"].type != "tree") {
			console.log("Error: expected source value to be a tree, but got: ", params["src"]);
			return undefined;
		}

		// treat all non-"src" keys as variable names
		var replace_vars = [];
		Object.keys(params)
			.filter(function (x) { return x != "src"; })
			.map(function (var_name) {
				var variable = QETerm.create({ type: "VARIABLE", value: var_name });

				// validate params[var_name] is a tree
				var replacement_value = params[var_name];
				if (replacement_value.type != "tree") {
					console.log("Error: expected VARIABLE replacement to be a tree: ", replacement_value);
					return undefined;
				}

				replace_vars.push({
					variable: variable,
					replacement_value: replacement_value.value,
				});
			});

		var desc = "Given: "+
			replace_vars.map(function (kv) {
				return kv.variable.display() +" = "+ kv.replacement_value.display();
			}).join(", ") +"<br><br>";

		// first pass to highlight variables
		var variable_matched = false;
		var clone_tree = tree.clone();
		replace_vars.map(function (kv) {
			var var_name = kv.variable.value;

			var vars = clone_tree.findAllChildren("type", "VARIABLE");
			for (var i = 0; i < vars.length; i++) {
				if (vars[i].value == var_name) {
					variable_matched = true;
					vars[i].addClass("highlight_input");
				}
			}
		});

		// nothing to replace
		if (!variable_matched) {
			return undefined;
		}

		// substitute the variables in the equation with their given values
		replace_vars.map(function (kv) {
			var var_name = kv.variable.value;
			var replacement_value = kv.replacement_value.children[0];

			// wrap replacement value in brackets if it isn't a simple value or already wrapped in brackets
			if (
				replacement_value.arity != "VALUE" &&
				replacement_value.type != "BRACKETS"
			) {
				var brackets = QETerm.create({ type: "BRACKETS" });
				brackets.pushChild(replacement_value);
				replacement_value = brackets;
			}

			// replace all matching instances of var
			var vars = tree.findAllChildren("type", "VARIABLE");
			for (var i = 0; i < vars.length; i++) {
				if (vars[i].value == var_name) {
					var replace_clone = replacement_value.clone();
					replace_clone.addClassToAll("highlight_output");

					vars[i].replaceWith(replace_clone);
				}
			}
		});

		desc += clone_tree.display() + " becomes " + tree.display();

		// strip highlighting
		tree.removeClassToAll("highlight_output");

		// canonicalize the tree - convert to chain-mode and pull any SIGNs out into sign fields.
		let canonicalized = QESolver.solveUsing("canonicalize", {
			type: "tree",
			value: tree.clone(),
		}).value;
		tree = canonicalized.value;

		let result = {
			desc: desc,
			value: tree,
			type: "tree",
		};

		return result;
	}

	/**
	 * expandPowerToChain  Converts a power term to a chain of "base" terms multiplied together "exp" times
	 */
	static expandPowerToChain(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var eq = input_value.value;

		var terms = eq.findAllChildren("type", "EXPONENT");
		for (let i = 0; i < terms.length; i++) {
			var term = terms[i];

			// validate that power is a positive integer
			if (
				term.children[1].type != "RATIONAL" ||
				!Number.isInteger(Number(term.children[1].value)) ||
				Number(term.children[1].value) < 1
			) {
				continue;
			}

			var base = term.children[0].serialize_to_text();
			var exponent = term.children[1].serialize_to_text();

			var expanded_list = [];
			for (var x = parseInt(exponent); x > 0; x--) {
				expanded_list.push(base);
			}

			var expanded_string = expanded_list.join("*");
			var new_term = tokenize_and_parse(expanded_string, { merge_sign_operators: true }).tree
				.children[0];

			var desc = "Some explanation of what we have done";

			return {
				old_term: term,
				new_term: new_term,
				type: "tree",
				desc: desc,
			};
		}
	}
	/**
	 * reduceChainToPower  Identifies common terms in a multiply chain and combines them together into a power
	 */
	static reduceChainToPower(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		var terms = root.findAllChildren(function (node) {
			// find multiply chains that contain no DIVIDE operators
			if (
				node.isMultiplyChain() &&
				node.findImmediateChildren("type", "DIVIDE").length == 0
			) {
				return true;
			}
		});

		for (let i = 0; i < terms.length; i++) {
			var chain = terms[i];

			// serialize terms for comparison
			var serial_values = [];
			for (var j = 0; j < chain.children.length; j += 2) {
				serial_values.push(chain.children[j].serialize_to_text());
			}

			// iterate over chain terms and keep a count of each
			var value_counts = {};
			serial_values.forEach(function (serial) {
				if (!value_counts[serial]) {
					value_counts[serial] = 0;
				}
				value_counts[serial]++;
			});

			// ensure thre is at least one value that appears multiple times
			var duplicates = Object.keys(value_counts).filter(function (
				serial
			) {
				return value_counts[serial] > 1;
			});

			if (!duplicates.length) {
				continue;
			}

			// iterate over serialized values and either clone or combine
			var new_terms = [];
			serial_values.forEach(function (serial, index) {
				if (value_counts[serial] > 1) {
					// create a new power
					var new_power = QETerm.create({
						type: "FUNCTION",
						value: "pow",
					});

					// set base
					new_power.pushChild(chain.children[index * 2].clone());

					// set exponent
					new_power.pushChild(
						QETerm.create({
							type: "RATIONAL",
							value: value_counts[serial].toString(),
						})
					);

					// output highlighting
					new_power.addClass("highlight_output");

					// clear value_counts[serial] so we ignore this value when we encounter it again
					value_counts[serial] = 0;

					new_terms.push(new_power);
				} else if (value_counts[serial] == 1) {
					new_terms.push(chain.children[index * 2].clone());
				}
			});

			// create new chain, with multi_keys items combined
			var new_chain = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("MULTIPLY").precedence,
			});

			for (var j = 0; j < new_terms.length; j++) {
				if (j > 0) {
					new_chain.pushChild(
						QETerm.create({ type: "MULTIPLY", value: "" })
					);
				}

				new_chain.pushChild(new_terms[j]);
			}

			// retain sign from original chain
			new_chain.sign = chain.sign;

			// single-element CHAINs are converted to single values
			new_chain = new_chain.unchainChildIfSingle();

			// iterate over serialized values and highlight matching terms in input chain
			serial_values.forEach(function (serial, index) {
				if (value_counts[serial] == 0) {
					// value count was cleared because it was a duplicate
					chain.children[index * 2].addClass("highlight_input");
				}
			});

			var desc =
				"Combine identical multiplied values into a power.<br>The exponent of the power is equal to the number of times the value appears.";

			return {
				old_term: chain,
				new_term: new_chain,
				type: "tree",
				desc: desc,
			};
		}
	}

	// Convert mixed numbers to improper fractions
	static CT_convertMixedNumberToFraction(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		var terms = root.findAllChildren("value", "mfrac");
		for (var term_index = 0; term_index < terms.length; term_index++) {
			var mfrac = terms[term_index];
			var sign = mfrac.sign || 1;

			// verify all parts are integers
			var mixed_parts = [];
			for (var i = 0; i < 3; i++) {
				if (
					mfrac.children[i].type == "RATIONAL" &&
					parseInt(mfrac.children[i].value) == mfrac.children[i].value
				) {
					mixed_parts.push(parseInt(mfrac.children[i].value));
				}

				if (mfrac.children[i].sign == -1) {
					sign *= -1;
				}
			}
			if (mixed_parts.length != 3) {
				continue;
			}

			var whole = mixed_parts[0];
			var num = mixed_parts[1];
			var den = mixed_parts[2];

			// whole + num/den  ==>  (whole * den + num) / den
			var whole_part = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("MULTIPLY").precedence,
			});
			whole_part.pushChild(
				QETerm.create({ type: "RATIONAL", value: whole.toString() })
			);
			whole_part.pushChild(QETerm.create({ type: "MULTIPLY" }));
			whole_part.pushChild(
				QETerm.create({ type: "RATIONAL", value: den.toString() })
			);

			var new_numerator = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("ADD").precedence,
			});
			new_numerator.pushChild(whole_part);
			new_numerator.pushChild(QETerm.create({ type: "ADD" }));
			new_numerator.pushChild(
				QETerm.create({ type: "RATIONAL", value: num.toString() })
			);

			var new_term = QETerm.create({ type: "FUNCTION", value: "frac" });
			new_term.pushChild(new_numerator);
			new_term.pushChild(
				QETerm.create({ type: "RATIONAL", value: den.toString() })
			);

			// retain sign
			new_term.sign = sign;

			// highlighting
			mfrac.addClass("highlight_input");
			new_term.addClass("highlight_output");

			return {
				old_term: mfrac,
				new_term: new_term,
				type: "tree",
				desc:
					"Turn a mixed number into a fraction by multiplying whole the part with the denominator, then adding to the numerator",
			};
		}
	}

	// Convert improper fractions to mixed numbers as a one-step operation
	// This is a "final" step. The solution will stop after applying it.
	static CT_convertFractionToMixedNumberSimplified(input_value, options) {
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;

		const terms = root.findAllChildren("value", "frac");
		for (let term_index = 0; term_index < terms.length; term_index++) {
			const frac = terms[term_index];

			let sign = frac.sign || 1;

			// verify numerator and denominator are integers and the numerator > denominator
			const frac_parts = [];
			for (let i = 0; i < 2; i++) {
				if (
					frac.children[i].type == "RATIONAL" &&
					parseInt(frac.children[i].value) == frac.children[i].value
				) {
					frac_parts.push(parseInt(frac.children[i].value));
				}

				// retain sign
				if (frac.children[i].sign == -1) {
					sign *= -1;
				}
			}
			if (frac_parts.length != 2) {
				continue;
			}

			const num = frac_parts[0];
			const den = frac_parts[1];

			if (num < den) {
				// fraction is proper. Can't convert to mixed
				continue;
			}

			// numerator/denominator  ==>  whole_part + remainder/denominator
			const whole_part = Math.trunc(num / den);
			const remainder = num - whole_part * den;

			let new_term;

			// if remainder is zero, return the whole part as an integer
			if (remainder == 0) {
				new_term = QETerm.create({
					type: "RATIONAL",
					value: whole_part.toString(),
				});
			} else {
				new_term = QETerm.create({ type: "FUNCTION", value: "mfrac" });
				new_term.pushChild(
					QETerm.create({
						type: "RATIONAL",
						value: whole_part.toString(),
					})
				);
				new_term.pushChild(
					QETerm.create({
						type: "RATIONAL",
						value: remainder.toString(),
					})
				);
				new_term.pushChild(
					QETerm.create({ type: "RATIONAL", value: den.toString() })
				);
			}

			// retain sign
			new_term.sign = sign;

			// highlighting
			frac.addClass("highlight_input");
			new_term.addClass("highlight_output");

			return {
				old_term: frac,
				new_term: new_term,
				type: "tree",
				desc:
					"Turn an improper fraction into a mixed number by dividing the numerator by the denominator. The remainder is left as the fraction portion of the mixed number",
				next_solution_step: undefined,
			};
		}
	}

	/**
	 * CT_convertMixedMultiplicativeChainToFraction	Combine chains of MULTIPLY and DIVIDE into a single complex fraction.
	 */
	static CT_convertMixedMultiplicativeChainToFraction(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find fractions or DIVIDEs in multiplicative CHAINs
		var terms = root.findAllChildren(function (node) {
			if (
				(node.value == "frac" || node.type == "DIVIDE") &&
				node.parent.isMultiplyChain()
			) {
				return true;
			}
		});

		for (let i = 0; i < terms.length; i++) {
			// get the parent chain
			var chain = terms[i].parent;

			// track which terms belong in the numerator and denominator
			var numerator_terms = [];
			var denominator_terms = [];
			var sign = 1;

			var invalid_child = false;
			for (var j = 0; j < chain.children.length; j += 2) {
				var term = chain.children[j];

				if (term.value == "mfrac") {
					// skip chains with mfrac children - convert to frac first
					invalid_child = true;
					break;
				} else if (term.value == "frac") {
					let term_num_clone;
					let term_den_clone;

					// fraction, now check if we're dividing
					if (j > 0 && chain.children[j - 1].type == "DIVIDE") {
						// divide: invert fraction
						term_num_clone = term.children[1].clone();
						term_den_clone = term.children[0].clone();
					} else {
						// multiply
						term_num_clone = term.children[0].clone();
						term_den_clone = term.children[1].clone();
					}

					// add highlighting - unwrap multiply chains now so we can appply highlight to each child term
					let term_num_clone_terms = multiplicativeTermToList(
						term_num_clone
					).map(function (x) {
						x.addClass("highlight_output");
						return x;
					});
					let term_den_clone_terms = multiplicativeTermToList(
						term_den_clone
					).map(function (x) {
						x.addClass("highlight_output");
						return x;
					});
					term.addClass("highlight_input");

					numerator_terms = numerator_terms.concat(
						term_num_clone_terms
					);
					denominator_terms = denominator_terms.concat(
						term_den_clone_terms
					);

					// keep running tally of sign
					sign = sign * (term.sign || 1);
				} else {
					let term_clone = term.clone();

					// non-fraction, now check if we're dividing
					if (j > 0 && chain.children[j - 1].type == "DIVIDE") {
						// divide: move term to denominator
						denominator_terms.push(term_clone);
					} else {
						// multiply
						numerator_terms.push(term_clone);
					}

					term_clone.addClass("highlight_output");
					term.addClass("highlight_input");
				}
			}
			if (invalid_child) {
				continue;
			}

			// create numerator and denominator terms
			let numerator = listToMultiplicativeTerm(numerator_terms);
			let denominator = listToMultiplicativeTerm(denominator_terms);

			// create fraction to replace chain
			var new_frac = QETerm.create({ type: "FUNCTION", value: "frac" });
			new_frac.pushChild(numerator);
			new_frac.pushChild(denominator);
			new_frac.sign = sign * (chain.sign || 1); // preserve chain sign, if any

			return {
				old_term: chain,
				new_term: new_frac,
				type: "tree",
				desc:
					"Combine multiplied or divided terms into a single fraction.",
			};
		}
	}

	/**
	 * CT_sortMultiplicativeChainTerms	Sort multiplied terms into a consistent order
	 */
	static CT_sortMultiplicativeChainTerms(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// filter to addititive CHAINs containing 1 or more fractions
		var terms = root.findAllChildren(function (node) {
			if (node.isMultiplyChain()) {
				return true;
			}
		});

		if (!terms.length) {
			return undefined;
		}

		var desc = "";

		// check if term is sorted
		for (let i = 0; i < terms.length; i++) {
			var chain = terms[i];

			// convert CHAIN to simple array
			var term_list = multiplicativeTermToList(chain);

			if (term_list === undefined || term_list.length == 1) {
				continue;
			}

			// sort array - returns undefined if already sorted
			var sorted_index_list = sortMultiplicativeTermList(term_list);
			if (!sorted_index_list) {
				continue;
			}

			// build new CHAIN from sorted_index_list
			var new_term = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("MULTIPLY").precedence,
			});
			new_term.pushChild(term_list[sorted_index_list[0]].clone());

			for (var j = 1; j < sorted_index_list.length; j++) {
				new_term.pushChild(QETerm.create({ type: "MULTIPLY", value: "" }));
				new_term.pushChild(term_list[sorted_index_list[j]].clone());
			}

			// retain sign
			new_term.sign = chain.sign;

			return {
				old_term: chain,
				new_term: new_term,
				type: "tree",
				desc: "Standardize order of terms for easier comparison.",
			};
		}
	}
	/**
	 * CT_sortAdditiveChainTerms	Sort added terms into a consistent order
	 */
	static CT_sortAdditiveChainTerms(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// filter to addititive CHAINs containing 1 or more fractions
		var terms = root.findAllChildren(function (node) {
			if (node.isAddChain()) {
				return true;
			}
		});

		if (!terms.length) {
			return undefined;
		}

		// characterize tree - must be performed each time, since a step may have changed a term
		root.characterizeNode();

		var desc = "";

		// check if term is sorted
		for (let i = 0; i < terms.length; i++) {
			var chain = terms[i];

			// convert CHAIN to simple array
			var term_list = additiveChainToList(chain);

			if (term_list === undefined || term_list.length == 1) {
				continue;
			}

			// sort array - returns undefined if already sorted
			// NOTE: sortAdditiveTermList relies on characterizeNode characterization
			var sorted_index_list = sortAdditiveTermList(term_list);
			if (!sorted_index_list) {
				continue;
			}

			// build new CHAIN from sorted_index_list
			var new_chain = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("ADD").precedence,
			});

			var index = sorted_index_list[0] * 2;
			var old_term = chain.children[index];
			var new_term = old_term.clone();
			if (index) {
				// get preceding operator and combine with term sign
				var prev_op = chain.children[index - 1];
				if (prev_op.type == "SUBTRACT") {
					// invert new_term.sign
					new_term.sign = (new_term.sign || 1) * -1;
				}
			}
			new_chain.pushChild(new_term);

			for (var j = 1; j < sorted_index_list.length; j++) {
				// get sign and preceding op from original term
				index = sorted_index_list[j] * 2;
				old_term = chain.children[index];
				new_term = old_term.clone();

				if (index) {
					var prev_op = chain.children[index - 1];
					if (prev_op.type == "SUBTRACT") {
						if (old_term.sign == -1) {
							// invert new_term.sign and add instead
							new_chain.pushChild(QETerm.create({ type: "ADD" }));
							new_term.sign = (new_term.sign || 1) * -1;
						} else {
							new_chain.pushChild(
								QETerm.create({ type: "SUBTRACT" })
							);
						}
					} else {
						// prev_op is ADD
						if (old_term.sign == -1) {
							// invert new_term.sign and subtract instead
							new_chain.pushChild(
								QETerm.create({ type: "SUBTRACT" })
							);
							new_term.sign = (new_term.sign || 1) * -1;
						} else {
							new_chain.pushChild(QETerm.create({ type: "ADD" }));
						}
					}
					new_chain.pushChild(new_term);
				} else {
					// implied ADD prev_op for index zero
					if (old_term.sign == -1) {
						// invert new_term.sign and subtract instead
						new_chain.pushChild(QETerm.create({ type: "SUBTRACT" }));
						new_term.sign = (new_term.sign || 1) * -1;
					} else {
						new_chain.pushChild(QETerm.create({ type: "ADD" }));
					}
					new_chain.pushChild(new_term);
				}
			}

			return {
				old_term: chain,
				new_term: new_chain,
				type: "tree",
				desc:
					"Standardize order of terms in an expression for easier comparison.",
			};
		}
	}

	/**
	 * CT_isolateVar_AddSubtractVarTermToLHS	Move variable terms to the left hand side of an equation
	 */
	static CT_isolateVar_AddSubtractVarTermToLHS(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// TODO: add to solver expected options config
		let var_name;
		if (options.var_name instanceof QEValue) {
			var_name = options.var_name.serialize_to_text();
		} else {
			var_name = options.var_name || "x";
		}

		// filter to addititive CHAINs containing 1 or more fractions
		var terms = root.findAllChildren(function (node) {
			if (node.isComparatorChain() && node.children.length == 3) {
				return true;
			}
		});

		if (!terms.length) {
			return undefined;
		}

		// find terms on the right hand side that contain the specified variable
		for (let i = 0; i < terms.length; i++) {
			let chain = terms[i];

			let lhs_terms_list = additiveChainToList(chain.children[0]);
			let rhs_terms_list = additiveChainToList(chain.children[2]);

			// find terms on RHS where factors include var_name and move them to the LHS
			let num_moved_terms = 0;
			for (let j = 0; j < rhs_terms_list.length; j++) {
				let rhs_term = rhs_terms_list[j];
				let rhs_term_factors = getCharacterizedTermFactors(rhs_term, {});

				// TODO: also check root_index factors
				// TODO: move into SolverUtils.factorMapHasFactor() helper
				// check if term factors include var_name
				if (rhs_term_factors.num[var_name] ||
					rhs_term_factors.den[var_name] ||
					(rhs_term_factors.non_int_num || {})[var_name] ||
					(rhs_term_factors.non_int_den || {})[var_name]
				){
					// move term to lhs and negate it (subtract from both sides)
					let clone_term = rhs_term.clone();
					clone_term.negate();

					lhs_terms_list.push(clone_term);
					num_moved_terms++;

					// apply highlight class to rhs_term
					rhs_term.addClass("highlight_input");

					// apply highlight_output class to clone_term
					clone_term.addClass("highlight_output");

					// splice out of rhs_terms_list
					rhs_terms_list.splice(j, 1);
					j--;
				}
			}

			if (!num_moved_terms) {
				continue; // nothing to move
			}

			// create new lhs and rhs
			let new_lhs = listToAdditiveChain(lhs_terms_list);
			let new_rhs = listToAdditiveChain(rhs_terms_list);

			// create new comparator chain with new lhs and rhs
			let new_chain = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("EQUAL").precedence,
			});

			new_chain.pushChild(new_lhs);
			new_chain.pushChild(chain.children[1].clone());
			new_chain.pushChild(new_rhs);

			return {
				old_term: chain,
				new_term: new_chain,
				type: "tree",
				desc:
					"Move terms that contain <katex>" +
					var_name +
					"</katex> to the left hand side by adding or subtracting them from both sides",
			};
		}
	}

	/**
	 * CT_isolateVar_AddSubtractNonVarTermToRHS	Move non-variable terms to right hand side of equation
	 */
	static CT_isolateVar_AddSubtractNonVarTermToRHS(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// TODO: add to solver expected options config
		let var_name;
		if (options.var_name instanceof QEValue) {
			var_name = options.var_name.serialize_to_text();
		} else {
			var_name = options.var_name || "x";
		}

		// filter to addititive CHAINs containing 1 or more fractions
		var terms = root.findAllChildren(function (node) {
			if (node.isComparatorChain() && node.children.length == 3) {
				return true;
			}
		});

		if (!terms.length) {
			return undefined;
		}

		// find terms on the left hand side that do not contain the specified variable
		for (let i = 0; i < terms.length; i++) {
			let chain = terms[i];

			let lhs_terms_list = additiveChainToList(chain.children[0]);
			let rhs_terms_list = additiveChainToList(chain.children[2]);

			// find terms on RHS where factors include var_name and move them to the LHS
			let num_moved_terms = 0;
			for (let j = 0; j < lhs_terms_list.length; j++) {
				let lhs_term = lhs_terms_list[j];

				if (lhs_term.type == "RATIONAL" && lhs_term.Q.num == 0) {
					// skip "0" term
					continue;
				}

				let lhs_term_factors = getCharacterizedTermFactors(lhs_term, {});

				// TODO: also check root_index factors
				// TODO: move into SolverUtils.factorMapHasFactor() helper
				// check if term factors include var_name
				if (!lhs_term_factors.num[var_name] &&
					!lhs_term_factors.den[var_name] &&
					!(lhs_term_factors.non_int_num || {})[var_name] &&
					!(lhs_term_factors.non_int_den || {})[var_name]
				){
					// move term to rhs and negate it (subtract from both sides)
					let clone_term = lhs_term.clone();
					clone_term.negate();

					rhs_terms_list.push(clone_term);
					num_moved_terms++;

					// apply highlight class to lhs_term
					lhs_term.addClass("highlight_input");

					// apply highlight_output class to clone_term
					clone_term.addClass("highlight_output");

					// splice out of lhs_terms_list
					lhs_terms_list.splice(j, 1);
					j--;
				}
			}

			if (!num_moved_terms) {
				continue; // nothing to move
			}

			// create new lhs and rhs
			let new_lhs = listToAdditiveChain(lhs_terms_list);
			let new_rhs = listToAdditiveChain(rhs_terms_list);

			// create new comparator chain with new lhs and rhs
			let new_chain = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("EQUAL").precedence,
			});

			new_chain.pushChild(new_lhs);
			new_chain.pushChild(chain.children[1].clone());
			new_chain.pushChild(new_rhs);

			return {
				old_term: chain,
				new_term: new_chain,
				type: "tree",
				desc:
					"Move terms that do not contain <katex>" +
					var_name +
					"</katex> to the right hand side by adding or subtracting them from both sides",
			};
		}
	}

	/**
	 * CT_isolateVar_MultiplyDenominatorToRHS	Move denominators to right hand side of equation by multiplying on both sides
	 */
	static CT_isolateVar_MultiplyDenominatorToRHS(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// TODO: add to solver expected options config
		let var_name;
		if (options.var_name instanceof QEValue) {
			var_name = options.var_name.serialize_to_text();
		} else {
			var_name = options.var_name || "x";
		}

		// filter to addititive CHAINs containing 1 or more fractions
		var terms = root.findAllChildren(function (node) {
			if (
				node.isComparatorChain() &&
				node.children.length == 3 &&
				node.children[0].value == "frac"
			) {
				return true;
			}
		});

		if (!terms.length) {
			return undefined;
		}

		for (let i = 0; i < terms.length; i++) {
			let chain = terms[i];

			let lhs = chain.children[0];
			let rhs = chain.children[2];

			// cancel lhs denominator by cloning numerator
			let new_lhs = lhs.children[0].clone();

			// if lhs frac has a negative sign, keep it by applying it to the numerator
			if (lhs.sign == -1) {
				if (new_lhs.isAddChain()) {
					// if add chain, wrap in brackets prior to negating
					let brackets = QETerm.create({ type: "BRACKETS" });
					brackets.pushChild(new_lhs);

					new_lhs = brackets;
				}

				new_lhs.negate();
			}

			// multiply rhs by lhs denominator
			let lhs_den = lhs.children[1].clone();

			let rhs_terms_list = multiplicativeTermToList(rhs);
			let lhs_den_terms_list = multiplicativeTermToList(lhs_den);

			// NOTE: cloning lhs_den is wasteful, since listToMultiplicativeTerm will clone it as well, but we need to apply the output style here first
			lhs_den_terms_list.forEach(function (lhs_den_term) {
				lhs_den_term.addClass("highlight_output");
			});

			lhs.children[1].addClass("highlight_input");

			// create new rhs multiply chain from list of terms; retain rhs sign
			let new_rhs = listToMultiplicativeTerm(
				rhs_terms_list.concat(lhs_den_terms_list)
			);

			// retain sign
			new_rhs.sign = rhs.sign;

			// create new comparator chain with new lhs and rhs
			let new_chain = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("EQUAL").precedence,
			});

			new_chain.pushChild(new_lhs);
			new_chain.pushChild(chain.children[1].clone());
			new_chain.pushChild(new_rhs);

			return {
				old_term: chain,
				new_term: new_chain,
				type: "tree",
				desc:
					"Move denominator to the right hand side by multiplying it by both sides",
			};
		}
	}

	/**
	 * CT_isolateVar_MultiplyVarDenominator	Get rid of variable terms in fraction term denominators by multiplying both sides by the variable term
	 */
	static CT_isolateVar_MultiplyVarDenominator(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// TODO: non-integer exponents???

		// TODO: add to solver expected options config
		let var_name;
		if (options.var_name instanceof QEValue) {
			var_name = options.var_name.serialize_to_text();
		} else {
			var_name = options.var_name || "x";
		}

		// filter to addititive CHAINs containing 1 or more fractions
		var terms = root.findAllChildren(function (node) {
			if (node.isComparatorChain() && node.children.length == 3) {
				return true;
			}
		});

		if (!terms.length) {
			return undefined;
		}

		// characterize tree - must be performed each time, since a step may have changed a term
		root.characterizeNode();

		for (let i = 0; i < terms.length; i++) {
			let chain = terms[i];
			let lhs = chain.children[0];
			let rhs = chain.children[2];

			// turn both lhs and rhs into list of terms
			let lhs_terms_list = additiveChainToList(chain.children[2]);
			let rhs_terms_list = additiveChainToList(chain.children[2]);

			// iterate over both lists to find fraction terms with var_name in the denominator add_term_info.vars
			let max_var_degree = 0;
			let max_var_degree_term;
			lhs_terms_list.concat(rhs_terms_list).forEach(function (term) {
				if (
					term.value == "frac" &&
					term.children[1].characterization.add_term_info.vars[
						var_name
					] > max_var_degree
				) {
					max_var_degree =
						term.children[1].characterization.add_term_info.vars[
							var_name
						];
					max_var_degree_term = term.children[1];
				}
			});

			if (!max_var_degree) {
				continue;
			}

			// multiply both sides by a new variable term of degree max_var_degree, to remove the variable from any denominators
			let lhs_multiplier;
			let rhs_multiplier;
			if (max_var_degree > 1) {
				lhs_multiplier = QETerm.create({ type: "FUNCTION", value: "pow" });
				lhs_multiplier.pushChild(
					QETerm.create({ type: "VARIABLE", value: var_name })
				);
				lhs_multiplier.pushChild(
					QETerm.create({
						type: "RATIONAL",
						value: max_var_degree.toString(),
					})
				);

				rhs_multiplier = QETerm.create({ type: "FUNCTION", value: "pow" });
				rhs_multiplier.pushChild(
					QETerm.create({ type: "VARIABLE", value: var_name })
				);
				rhs_multiplier.pushChild(
					QETerm.create({
						type: "RATIONAL",
						value: max_var_degree.toString(),
					})
				);
			} else {
				lhs_multiplier = QETerm.create({
					type: "VARIABLE",
					value: var_name,
				});

				rhs_multiplier = QETerm.create({
					type: "VARIABLE",
					value: var_name,
				});
			}

			max_var_degree_term.addClass("highlight_input");
			lhs_multiplier.addClass("highlight_output");
			rhs_multiplier.addClass("highlight_output");

			let lhs_mult_terms_list = multiplicativeTermToList(lhs);
			let rhs_mult_terms_list = multiplicativeTermToList(rhs);

			// create new multiply chains on each side and multiply by new variable term
			let new_lhs = listToMultiplicativeTerm(
				lhs_mult_terms_list.concat(lhs_multiplier)
			);
			let new_rhs = listToMultiplicativeTerm(
				rhs_mult_terms_list.concat(rhs_multiplier)
			);

			// retain signs
			new_lhs.sign = lhs.sign;
			new_rhs.sign = rhs.sign;

			// create new comparator chain with new lhs and rhs
			let new_chain = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("EQUAL").precedence,
			});

			new_chain.pushChild(new_lhs);
			new_chain.pushChild(chain.children[1].clone());
			new_chain.pushChild(new_rhs);

			return {
				old_term: chain,
				new_term: new_chain,
				type: "tree",
				desc:
					"Remove a variable term in the denominator by multiplying it by both sides",
			};
		}
	}

	/**
	 * CT_isolateVar_DivideNonVarToRHS	Move coefficients to right hand side of equation by dividing on both sides
	 */
	static CT_isolateVar_DivideNonVarToRHS(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// TODO: add to solver expected options config
		let var_name;
		if (options.var_name instanceof QEValue) {
			var_name = options.var_name.serialize_to_text();
		} else {
			var_name = options.var_name || "x";
		}

		// filter to addititive CHAINs containing 1 or more fractions
		var terms = root.findAllChildren(function (node) {
			if (
				node.isComparatorChain() &&
				node.children.length == 3 &&
				node.children[0].isMultiplyChain()
			) {
				return true;
			}
		});

		if (!terms.length) {
			return undefined;
		}

		// characterize tree - must be performed each time, since a step may have changed a term
		root.characterizeNode();

		// find terms on the left hand side that do not contain the specified variable
		for (let i = 0; i < terms.length; i++) {
			let chain = terms[i];

			let lhs = chain.children[0];
			let rhs = chain.children[2];

			// find non-var factors on lhs
			let lhs_terms_list = multiplicativeTermToList(lhs);

			// find terms on LHS where add_term_info does not contain vars[var_name]
			let var_terms = [];
			let cloned_non_var_terms = [];

			for (let j = 0; j < lhs_terms_list.length; j++) {
				let lhs_term = lhs_terms_list[j];

				// check if term contains our specified var
				if (
					lhs_term.characterization.add_term_info.vars[var_name] > 0
				) {
					var_terms.push(lhs_term);
				} else {
					// clone term and merge in flipped preceding operator, if any
					let clone_term = lhs_term.clone();

					// apply strikethrough class to lhs_term
					lhs_term.addClass("highlight_input");

					// apply highlight_output class to clone_term
					clone_term.addClass("highlight_output");

					cloned_non_var_terms.push(clone_term);
				}
			}

			if (!cloned_non_var_terms.length) {
				continue; // nothing to move
			}

			// create new lhs from cloned var_terms
			let new_lhs = listToMultiplicativeTerm(var_terms);

			// retain sign
			new_lhs.sign = lhs.sign;

			let rhs_divisor = listToMultiplicativeTerm(cloned_non_var_terms);

			// divide rhs by rhs_divisor
			let new_rhs = createDividedTerm(rhs, rhs_divisor);

			// create new comparator chain with new lhs and rhs
			let new_chain = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("EQUAL").precedence,
			});

			new_chain.pushChild(new_lhs);
			new_chain.pushChild(chain.children[1].clone());
			new_chain.pushChild(new_rhs);

			return {
				old_term: chain,
				new_term: new_chain,
				type: "tree",
				desc:
					"Move left-hand-side coefficient to the right hand side by dividing both sides",
			};
		}
	}

	/**
	 * CT_isolateVar_MultiplyNegativeToRHS	Move negative sign to right hand side of equation by multiplying both sides by -1
	 */
	static CT_isolateVar_MultiplyNegativeToRHS(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// TODO: add to solver expected options config
		let var_name;
		if (options.var_name instanceof QEValue) {
			var_name = options.var_name.serialize_to_text();
		} else {
			var_name = options.var_name || "x";
		}

		// filter to addititive CHAINs containing 1 or more fractions
		var terms = root.findAllChildren(function (node) {
			if (node.isComparatorChain() && node.children.length == 3) {
				return true;
			}
		});

		if (!terms.length) {
			return undefined;
		}

		// find terms on the left hand side that do not contain the specified variable
		for (let i = 0; i < terms.length; i++) {
			let chain = terms[i];

			let lhs = chain.children[0];
			let rhs = chain.children[2];

			// check if the sign of the left-most term on the lhs is negative
			if (lhs.isAddChain() && lhs.children[0].sign != -1) {
				continue;
			} else if (lhs.sign != -1) {
				continue;
			}

			// multiply both sides by -1
			let new_lhs = lhs.clone();
			let new_rhs = rhs.clone();

			if (lhs.isAddChain()) {
				// wrap in brackets
				let brackets = QETerm.create({ type: "BRACKETS" });
				brackets.sign = -1;
				brackets.pushChild(new_lhs);

				brackets.addClass("highlight_output_sign");
				new_lhs = brackets;
			} else {
				lhs.addClass("strikethrough_input_sign");

				new_lhs.negate();
			}

			if (rhs.isAddChain()) {
				// wrap in brackets
				let brackets = QETerm.create({ type: "BRACKETS" });
				brackets.sign = -1;
				brackets.pushChild(new_rhs);

				brackets.addClass("highlight_output_sign");
				new_rhs = brackets;
			} else {
				new_rhs.negate();

				if (rhs.sign == -1) {
					rhs.addClass("strikethrough_input_sign");
				} else {
					new_rhs.addClass("highlight_output_sign");
				}
			}

			var desc =
				"Move a negative sign to the right hand side by multiplying both sides by -1";

			// create new comparator chain with new lhs and rhs
			let new_chain = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("EQUAL").precedence,
			});
			new_chain.pushChild(new_lhs);

			// flip inequality comparators when multiplying by a negative number
			let flipped_comparitors = {
				"<": "GREATER",
				"<=": "GREATER_OR_EQUAL",
				">": "LESS",
				">=": "LESS_OR_EQUAL",
			};

			let new_comparison;
			if (chain.children[1].value == "=") {
				new_comparison = chain.children[1].clone();
			} else {
				desc += "<br>";
				desc +=
					"When multiplying an inequality by a negative, the comparator gets flipped.";

				chain.children[1].addClass("highlight_input");
				new_comparison = QETerm.create({
					type: flipped_comparitors[chain.children[1].value],
				});
				new_comparison.addClass("highlight_output");
			}
			new_chain.pushChild(new_comparison);

			new_chain.pushChild(new_rhs);

			return {
				old_term: chain,
				new_term: new_chain,
				type: "tree",
				desc: desc,
			};
		}
	}

	/**
	 * sortFractionListByAscend	Sort a list of numeric values in ascending order
	 */
	static sortFractionListByAscend(input_value, options) {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a non-empty list
		if (
			input_value.value.children[0].type != "FUNCTION" ||
			input_value.value.children[0].value != "list"
		) {
			return undefined;
		}
		let old_term = input_value.value.children[0];
		let terms = old_term.children;

		if (!terms.length) {
			return undefined;
		}

		// check whether each term can be evaluated to float
		for (let i = 0; i < terms.length; i++) {
			if (isNaN(terms[i].evaluate_to_float())) {
				console.log(
					"Warning: sortFractionListByAscend list value isNaN: ",
					terms[i].serialize_to_text()
				);
				return undefined;
			}
		}

		let sorted_terms = terms.concat();
		sorted_terms.sort(function (a, b) {
			if (a.evaluate_to_float() > b.evaluate_to_float()) return 1;
			if (b.evaluate_to_float() > a.evaluate_to_float()) return -1;

			return 0;
		});

		let new_term = QETerm.create({ type: "FUNCTION", value: "list" });
		new_term.pushChild(sorted_terms[0].clone());

		for (let j = 1; j < sorted_terms.length; j++) {
			new_term.pushChild(sorted_terms[j].clone());
		}

		// check if list already sorted
		let serialized_old_list = old_term.serialize_to_text();
		let serialized_new_list = new_term.serialize_to_text();
		if (serialized_old_list == serialized_new_list) {
			// already sorted
			return undefined;
		}

		return {
			old_term: old_term,
			new_term: new_term,
			type: "tree",
			desc: "Order the fractions from least to greatest.",
		};
	}

	/**
	 * prependLeadingZero  Prepend a zero to incorrectly formatted decimal numbers. E.g. '.5' -> '0.5'
	 */
	static prependLeadingZero(input_value, options) {
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;

		const terms = root.findAllChildren(function (node) {
			return node.type == "RATIONAL" && node.value.match(/^\./);
		});

		for (let i = 0; i < terms.length; i++) {
			const term = terms[i];

			// create new term with corrected value
			const fixed_num = QETerm.create({ type: "RATIONAL", value: '0'+ term.value });

			const desc = "Tip: don't forget to include a zero before a decimal place: e.g. .5 -> 0.5";
			return {
				old_term: term,
				new_term: fixed_num,
				type: "tree",
				desc: desc,
			};
		}
	}

	/**
	 * stripTrailingZeros  Strips redundant trailing zeros.. E.g. '0.500' -> '0.5'
	 */
	static stripTrailingZeros(input_value, options) {
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;

		const terms = root.findAllChildren(function (node) {
			return node.type == "RATIONAL" && node.value.match(/\.\d*0+$/);
		});

		for (let i = 0; i < terms.length; i++) {
			const term = terms[i];

			// create new term with corrected value
			const fixed_num = QETerm.create({ type: "RATIONAL", value: term.value.replace(/0+$/, '').replace(/\.$/, '') });

			var desc = "Tip: trailing zeros in the decimal are unneeded and should be removed. E.g. 0.500 -> 0.5"+
				"<br>(Note: an exception to this is when they are being used to indicate precision!)";
			return {
				old_term: term,
				new_term: fixed_num,
				type: "tree",
				desc: desc,
			};
		}
	}

	/*
	* unflattenPolygon - turns a flat structure polygon, like {x1: a, x2: b, y1: c, y2: d} 
	* into a non flat polygon array, like [{x: a, y: c}, {x:b, y:d}]
	*/
	static unflattenPolygon(input_value, options) {
		let value = input_value.value
		let unflattened = []
		if(value.length == 1) {
			for(let curr in value[0]){
				let key = curr.charAt(0);
				let index = parseFloat(curr.slice(1))
				if(!isNaN(index)) {
					if(!unflattened[index - 1]){
						unflattened[index - 1] = {};
					}
					unflattened[index - 1][key] = value[0][curr];
				}
			}
			return {
				desc: "",
				value: unflattened,
				type: "json",
			}
		} else{
			return undefined
		}
	}
	/*
	* convertToSimplePolygon  Turns a Polygon, like [{x: a, y:b }, ... {x: c, y:d}] into a simple polygon
	* that is a polygon that doesn't intersect itself.
	*/
	static convertToSimplePolygon(input_value, options) {
		if(input_value.type != "json") {
			return undefined
		}
		if(input_value.value.length <= 1){
			return undefined
		}
		let newPolygon = SimplePolygon(input_value.value)
		//Check if the new polygon is the same as the old one, ie that the step is complete	
		for(let i = 0; i < newPolygon.length; i++){
			if(newPolygon[i].x != input_value.value[i].x || newPolygon[i].x != input_value.value[i].x ){
				return {
					desc: "",
					value: newPolygon,
					type: "json",
				}
			}
		}
		return undefined;
	}
	
	/*
	* flattenPolygon Turns a polygon of form [{x: a, y: b}, {x:c, y:d} ... ] into a flat structure
	* of form {x1:a, x2:c, y1:b, y2:d ... }
	*/
	static flattenPolygon(input_value, options){
		if(input_value.type != "json") {
			return undefined
		}
		let flattened = {};
		let value = input_value.value
		if(value.length > 1) {
			for(let i = 0; i < value.length; i++){
				for(let curr in value[i]) {
					flattened[curr + (i + 1)] = value[i][curr];
				}
			}
			flattened["num_sides"] = value.length;
			return {
				desc: "",
				value: [flattened],
				type: "json",
				final_step: true
			}
		} else if(value.length > 0 && !value["num_sides"]) {
			//already a flat structure, but needs num_sides in order to be consistent with SVGRenderingContext
			let flattened = value[0];
			let num_sides = 0;
			flattened["num_sides"] = 0;
			for(let curr in value[0]){
				if(!isNaN(parseFloat(curr.slice(1)))){
					flattened["num_sides"] = Math.max(flattened["num_sides"], parseFloat(curr.slice(1)))
				}
			}
			return {
				desc: "",
				value: [flattened],
				type: "json",
				final_step: true //This is a less then ideal way of doing it. It's so the simple polygon solver doesn't become an infintie loop
			}
		} else {
			return undefined
		}
	}
}