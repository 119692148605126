import { QESolverStep } from './QESolverStep';
import { QEQ } from '../../common/QE';
import { QETerm } from '../../common/QETerm.ts';
import { tokenize_and_parse, findGrammar } from '../../common/QEGrammar';
import { QESolver } from '../QESolver';

// Helper, compare two value.
export function comparisonResult(firstValue, secondValue, firstTerm, secondTerm, old_term) {
	const root = QETerm.create({type:"ROOT"});
	const comparator = QETerm.create({type:"LESS"});
	root.pushChild( comparator );
	comparator.pushChild( firstTerm.clone() );
	comparator.pushChild( secondTerm.clone() );

	if ( firstValue > secondValue ) {
		comparator.type = "GREATER";
		comparator.value = ">";
	} else if( 	firstValue < secondValue ) { 
		comparator.type = "LESS";
		comparator.value = "<";
	} else {
		comparator.type = "EQUAL";
		comparator.value = "=";
	}

	const desc = ""
	const result = {
		desc: desc,
		old_term: old_term,
		new_term: comparator,
		type: "tree",
	};
	return result;
}

///////////////////////////////////////////////
// helpers for term divisibility and factoring
///////////////////////////////////////////////

interface FactorMap {
	num: { [key: string]: number },
	den: { [key: string]: number },
	non_int_num?: { [key: string]: { [key: string]: number } },
	non_int_den?: { [key: string]: { [key: string]: number } },
	root_index?: {
		[key: string]: FactorMap
	}
}
// E.g. "6" -> { num: {2:1, 3:1}, den: {} }
// E.g. "9" -> { num: {3:2}, den: {} }
// E.g. "\pow{3,2}" -> { num: {3:2}, den: {} }
// E.g. "\pow{x,2}" -> { num: {x:2}, den: {} }
// E.g. "\pow{x,a}" -> { num: {}, den: {}, non_int_num: {x:{a:1}} }
// E.g. "\pow{6,a}" -> { num: {}, den: {}, non_int_num: {2:{a:1}, 3:{a:1}} }

/**
 * getSumOfCharacterizedTermListFactors - get the sum of all factors of passed factor_maps
 * @param {Object[]} factor_maps_list - a list of factor maps, each containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 * @param {Object} factor_maps_list[].num - a map of numerator factors with positive integer counts, e.g. "x^2"
 * @param {Object} factor_maps_list[].den - a map of denominator factors with positive integer counts, e.g. "1/(x^2)"
 * @param {Object} [factor_maps_list[].non_int_num] - a map of numerator factors with noninteger counts, e.g. "x^a"
 * @param {Object} [factor_maps_list[].non_int_den] - a map of denominator factors with noninteger counts, e.g. "1/(x^a)"
 * @param {Object} [factor_maps_list[].root_index] - a map of nested factor maps, keyed by root_index =, e.g. "sqrt{x}"
 * @returns {Object} factors - map containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 */
export function getSumOfCharacterizedTermListFactors(factor_maps_list: FactorMap[]): FactorMap {
	const factors: FactorMap = { num: {1: 1}, den: {1: 1} };

	// helper function for summing factor counts
	function addFactorsToTargetMap(source_map: FactorMap, target_map: FactorMap) {
		// sum factor counts for integer exponents
		['num', 'den'].forEach(function(key){
			for (let factor in (source_map[key] || {})) {
				// initialize target map
				if (!target_map[key]) {
					target_map[key] = {1: 1};
				}

				// initialize factor count
				if (!target_map[key][factor]) {
					target_map[key][factor] = source_map[key][factor];
				} else {
					target_map[key][factor] += source_map[key][factor];
				}
			}
		});

		// sum factor counts for non-integer exponents - stored as map of serialized exponent and count, rather than just count
		['non_int_num', 'non_int_den'].forEach(function(key){
			for (let factor in (source_map[key] || {})) {
				// initialize target map
				if (!target_map[key]) {
					target_map[key] = {};
				}

				for (let serialized_exponent in (source_map[key][factor] || {})) {
					// initialize target map
					if (!target_map[key][factor]) {
						target_map[key][factor] = {};
					}

					// initialize factor count
					if (!target_map[key][factor][serialized_exponent]) {
						target_map[key][factor][serialized_exponent] = source_map[key][factor][serialized_exponent];
					} else {
						target_map[key][factor][serialized_exponent] += source_map[key][factor][serialized_exponent];
					}
				}
			}
		});

		// reset "1" factor counts
		Object.assign(target_map.num, {1: 1});
		Object.assign(target_map.den, {1: 1});
	}

	// loop over factor_maps_list and incrementally build factor counts
	for (let i = 0; i < factor_maps_list.length; i++) {
		const factor_map = factor_maps_list[i];

		// incrementally update factor counts
		addFactorsToTargetMap(factor_map, factors);


		// build factor counts for radical bases, for each root index value
		if (factor_map.root_index) {
			for (let root_index_value in factor_map.root_index) {
				// init
				if (!factors.root_index) {
					factors.root_index = {};
				}
				if (!factors.root_index[root_index_value]) {
					factors.root_index[root_index_value] = { num: {1: 1}, den: {1: 1} };
				}

				// incrementally update factor counts for specified root index value
				addFactorsToTargetMap(factor_map.root_index[root_index_value], factors.root_index[root_index_value]);
			}
		}
	}

	return factors;
}

/**
 * getUnionOfCharacterizedTermListFactors - get the union (max count of each) of all factors of passed factor_maps
 * @param {Object[]} factor_maps_list - a list of factor maps, each containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 * @param {Object} factor_maps_list[].num - a map of numerator factors with positive integer counts, e.g. "x^2"
 * @param {Object} factor_maps_list[].den - a map of denominator factors with positive integer counts, e.g. "1/(x^2)"
 * @param {Object} [factor_maps_list[].non_int_num] - a map of numerator factors with noninteger counts, e.g. "x^a"
 * @param {Object} [factor_maps_list[].non_int_den] - a map of denominator factors with noninteger counts, e.g. "1/(x^a)"
 * @param {Object} [factor_maps_list[].root_index] - a map of nested factor maps, keyed by root_index =, e.g. "sqrt{x}"
 * @returns {Object} factors - map containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 */
export function getUnionOfCharacterizedTermListFactors(factor_maps_list: FactorMap[]): FactorMap {
	const factors: FactorMap = { num: {1: 1}, den: {1: 1} };

	// helper function for summing factor counts
	function mergeFactorsToTargetMap(source_map: FactorMap, target_map: FactorMap) {
		// get MAX of factor counts for integer exponents
		['num', 'den'].forEach(function(key){
			for (let factor in (source_map[key] || {})) {
				// initialize target map
				if (!target_map[key]) {
					target_map[key] = {1: 1};
				}

				// initialize factor count
				if (!target_map[key][factor]) {
					target_map[key][factor] = source_map[key][factor];
				} else {
					target_map[key][factor] = Math.max(target_map[key][factor], source_map[key][factor]);
				}
			}
		});

		// get MAX of factor counts for non-integer exponents - stored as map of serialized exponent and count, rather than just count
		['non_int_num', 'non_int_den'].forEach(function(key){
			for (let factor in (source_map[key] || {})) {
				// initialize target map
				if (!target_map[key]) {
					target_map[key] = {};
				}

				for (let serialized_exponent in (source_map[key][factor] || {})) {
					// initialize target map
					if (!target_map[key][factor]) {
						target_map[key][factor] = {};
					}

					// initialize factor count
					if (!target_map[key][factor][serialized_exponent]) {
						target_map[key][factor][serialized_exponent] = source_map[key][factor][serialized_exponent];
					} else {
						target_map[key][factor][serialized_exponent] = Math.max(target_map[key][factor][serialized_exponent], source_map[key][factor][serialized_exponent]);
					}
				}
			}
		});

		// reset "1" factor counts
		Object.assign(target_map.num, {1: 1});
		Object.assign(target_map.den, {1: 1});
	}

	// loop over factor_maps_list and incrementally build factor counts
	for (let i = 0; i < factor_maps_list.length; i++) {
		const factor_map = factor_maps_list[i];

		// incrementally update factor counts
		mergeFactorsToTargetMap(factor_map, factors);


		// build factor counts for radical bases, for each root index value
		if (factor_map.root_index) {
			for (let root_index_value in factor_map.root_index) {
				// init
				if (!factors.root_index) {
					factors.root_index = {};
				}
				if (!factors.root_index[root_index_value]) {
					factors.root_index[root_index_value] = { num: {1: 1}, den: {1: 1} };
				}

				// incrementally update factor counts for specified root index value
				mergeFactorsToTargetMap(factor_map.root_index[root_index_value], factors.root_index[root_index_value]);
			}
		}
	}

	return factors;
}

/**
 * getIntersectionOfCharacterizedTermListFactors - get the min intersection of all factors of the passed factor_maps
 * @param {Object[]} factor_maps_list - a list of factor maps, each containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 * @param {Object} util_options
 * @param {bool} [util_options.use_decimal_den_2_5_padding] - flag to enable decimal padding of "2" and "5" denominator factor counts for decimal comparisons
 *    e.g. "0.5 / 0.1" should have "0.1" -> "1/(2*5)" as the common factor, not "0.5" -> "1/2", so a matching "5" factor should be added
 * @returns {Object} factors - map containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 */
export function getIntersectionOfCharacterizedTermListFactors(factor_maps_list: FactorMap[], util_options): FactorMap {
	util_options ||= {};

	// helper function for finding common factor counts
	function trimNonCommonFactorsFromTargetMap(source_map: FactorMap, target_map: FactorMap) {
		// get the MIN of the integer exponent of factors between source_map and target_map, and trim target_map
		['num', 'den'].forEach(function(key){
			if (!target_map[key]) {
				return; // forEach continue
			}

			// use combined factors to support use_decimal_den_2_5_padding, which uses MAX of 2 and 5 factor counts instead of MIN
			const combined_factors = Object.keys(Object.assign({}, source_map[key], target_map[key]));
			combined_factors.forEach(function(factor) {
				if (util_options.use_decimal_den_2_5_padding && key == 'den' && (factor == '2' || factor == '5')) {
					// if doing a decimal comparison, take the MAX of 2 and 5 den factors, instead of the min
					target_map[key][factor] = Math.max(source_map[key][factor] || 0, target_map[key][factor] || 0);
				} else if (!source_map[key][factor]) {
					// if factor missing from source map, delete it from target_map as well
					delete target_map[key][factor];
				} else if (target_map[key][factor]) {
					// keep the min of the factor across factor maps, excluding factors not already in target map
					target_map[key][factor] = Math.min(source_map[key][factor], target_map[key][factor]);
				}
			});
		});

		// get the MIN of the non-integer exponents of factors - stored as map of serialized exponent and count, rather than just count
		['non_int_num', 'non_int_den'].forEach(function(key){
			if (!target_map[key]) {
				return; // forEach continue
			} else if (!source_map[key]) {
				// if source map missing non-integer exponent factors entirely, we can trim them from target_map
				delete target_map[key];
				return; // forEach continue
			}

			for (let factor in target_map[key]) {
				for (let serialized_exponent in (target_map[key][factor] || {})) {
					if (!source_map[key][factor]) {
						// if factor missing from source map, delete it from target_map as well
						delete target_map[key][factor];
						continue;
					}

					for (let serialized_exponent in (target_map[key][factor])) {
						if (!source_map[key][factor][serialized_exponent]) {
							// if factor [serialized_exponent] missing from source map, delete it from target_map as well
							delete target_map[key][factor][serialized_exponent];
							continue;
						}

						// keep the min of the factor serialized_exponent across factor maps
						target_map[key][factor][serialized_exponent] = Math.min(source_map[key][factor][serialized_exponent], target_map[key][factor][serialized_exponent]);
					}

					// if target sub-map no longer has serialized_exponents for factor, delete factor
					if (!Object.keys(target_map[key][factor]).length) {
						delete target_map[key][factor];
					}
				}
			}

			// if target map no longer has factors for non_int_num or non_int_den, delete sub-map
			if (!Object.keys(target_map[key]).length) {
				delete target_map[key];
			}
		});

		// reset "1" factor counts
		Object.assign(target_map.num, {1: 1});
		Object.assign(target_map.den, {1: 1});
	}

	// clone the first factor map
	const factors = JSON.parse(JSON.stringify(factor_maps_list[0]));

	// loop over factor_maps_list and incrementally trim factor counts
	for (let i = 1; i < factor_maps_list.length; i++) {
		const factor_map = factor_maps_list[i];

		// trim any non-shared factors out of target map
		trimNonCommonFactorsFromTargetMap(factor_map, factors);

		// now do the same for each radical root index
		if (factors.root_index) {
			if (!factor_map.root_index) {
				// if source_map has no radical factors, we can delete all radical factors from target map
				delete factors.root_index;
				continue;
			}

			for (let root_index_value in factors.root_index) {
				if (!factor_map.root_index[root_index_value]) {
					// if root index value missing from source_map, we can delete that root index value from target map
					delete factors.root_index[root_index_value];
					continue;
				}

				// otherwise, trim any non-shared factors for this root index value
				trimNonCommonFactorsFromTargetMap(factor_map.root_index[root_index_value], factors.root_index[root_index_value]);

				// if target sub-map for root index value only contains empty num and den, delete
				if (Object.keys(factors.root_index[root_index_value]).length == 2 && // should always have num and den sub-maps
					Object.keys(factors.root_index[root_index_value].num).length == 1 &&
					Object.keys(factors.root_index[root_index_value].den).length == 1
				) {
					delete factors.root_index[root_index_value];
				}
			}

			// if target sub-map for root index no longer contains keys, delete
			if (Object.keys(factors.root_index).length == 0) {
				delete factors.root_index;
			}
		}
	}

	return factors;
}

/**
 * getDifferenceOfFactorMaps - subtracts the factors of two factor maps, inverting (num-to-den, or vice versa) any negative counts to make them positive
 * - this is division via factor reduction!
 * @param {Object} factor_maps_a - (minuend) map containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 * @param {Object} factor_maps_a - (subtrahend) map containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 * @returns {Object} difference_map - map containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 */
export function getDifferenceOfFactorMaps(factor_map_a: FactorMap = {num:{},den:{}}, factor_map_b: FactorMap = {num:{},den:{}}): FactorMap {

	// getFactorDifference helper for comparing factor counts
	function getFactorDifference(map1: FactorMap, map2: FactorMap, diff_map: FactorMap, complement_map: FactorMap){
		// check all factors across both maps
		const combined_factors = Object.keys(Object.assign({}, map1, map2));
		combined_factors.forEach(function(factor){
			if (map1[factor] > (map2[factor] || 0)) {
				if (!diff_map[factor]) {
					diff_map[factor] = 0; // init
				}

				diff_map[factor] += map1[factor] - (map2[factor] || 0);
			} else if (map2[factor] > (map1[factor] || 0)) {
				// map2 factor count is greater, so add factor count to complement
				if (!complement_map[factor]) {
					complement_map[factor] = 0; // init
				}

				complement_map[factor] += map2[factor] - (map1[factor] || 0);
			}
		});
	}

	function initAndCompare(map1: FactorMap, map2: FactorMap, diff_map: FactorMap, map_key: string, complement_key: string) {
		if (map1[map_key] || map2[map_key]) {
			if (!diff_map[map_key]) {
				diff_map[map_key] = {};
			}
			if (!diff_map[complement_key]) {
				diff_map[complement_key] = {};
			}
			getFactorDifference(map1[map_key] || {}, map2[map_key] || {}, diff_map[map_key], diff_map[complement_key]);
		}
	}

	const difference_map: FactorMap = { num: {}, den: {}};

	// iterate over num, den, non_int_num, non_int_den
	initAndCompare(factor_map_a, factor_map_b, difference_map, "num", "den");
	initAndCompare(factor_map_a, factor_map_b, difference_map, "den", "num");
	initAndCompare(factor_map_a, factor_map_b, difference_map, "non_int_num", "non_int_den");
	initAndCompare(factor_map_a, factor_map_b, difference_map, "non_int_den", "non_int_num");

	// call for individual root_indexes, if any set
	if (factor_map_a.root_index || factor_map_b.root_index) {
		difference_map.root_index = {};

		// get combined list of root_indexes across factor maps
		const root_indexes_a = factor_map_a.root_index || {};
		const root_indexes_b = factor_map_b.root_index || {};
		const root_indexes = Object.keys(Object.assign({}, root_indexes_a, root_indexes_b));

		// find difference of factor maps for each root_index
		for (let i = 0; i < root_indexes.length; i++) {
			const root_index = root_indexes[i];
			difference_map.root_index[root_index] = getDifferenceOfFactorMaps(root_indexes_a[root_index], root_indexes_b[root_index]);
		}
	}

	// reset "1" num/den factor counts
	Object.assign(difference_map.num, {1: 1});
	Object.assign(difference_map.den, {1: 1});

	return difference_map;
}

/**
 * areFactorMapsEquivalent - compare the factor maps and check if they have the same counts for each factor. If so, they are considered equivalent
 * @param {Object} factor_map_a - map containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 * @param {Object} factor_map_b - map containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 * @returns {bool} equivalent - true/false value indicating whether the factor maps have equal factor counts for all factors
 */
function areFactorMapsEquivalent(factor_map_a: FactorMap = {num:{},den:{}}, factor_map_b: FactorMap = {num:{},den:{}}) {
	// helper for comparing factor counts
	function hasFactorDifference(map1: FactorMap, map2: FactorMap){
		const combined_factors_list = Object.keys(Object.assign({}, map1, map2));
		for (let i = 0; i < combined_factors_list.length; i++) {
			const factor = combined_factors_list[i];
			if (factor == "1") continue; // skip "1"

			if (map1[factor] != map2[factor]) {
				return true;
			}
		}
		return false;
	}

	function factorMapsDiffer(map1: FactorMap, map2: FactorMap, map_key: string) {
		if (!map1[map_key] && !map2[map_key]) {
			// key missing for both maps
			return false;
		} else if (map1[map_key] && map2[map_key]) {
			return hasFactorDifference(map1[map_key], map2[map_key]);
		} else {
			// key missing for one of the maps
			return true;
		}
	}

	// iterate over num, den, non_int_num, non_int_den
	if (factorMapsDiffer(factor_map_a, factor_map_b, "num") ||
		factorMapsDiffer(factor_map_a, factor_map_b, "den") ||
		factorMapsDiffer(factor_map_a, factor_map_b, "non_int_num") ||
		factorMapsDiffer(factor_map_a, factor_map_b, "non_int_den")
	) {
		// has differences, not equivalent
		return false;
	}

	// check for individual root_indexes, if any set
	if (!factor_map_a.root_index && !factor_map_b.root_index) {
		// neither has root_index factors
		return true;
	} else if (factor_map_a.root_index && factor_map_b.root_index) {
		// for each root_index value, check if nested factors are equivalent
		const root_indexes = Object.keys(Object.assign({}, factor_map_a.root_index, factor_map_b.root_index));
		for (let i = 0; i < root_indexes.length; i++) {
			const root_index = root_indexes[i];
			if (!areFactorMapsEquivalent(factor_map_a.root_index[root_index], factor_map_b.root_index[root_index])) {
				return false
			}
		}
		// no differences found
		return true;
	} else {
		// one root_index map set, the other missing
		return false;
	}
}

/**
 * getChainChildTermFactorMaps - creates a factor_map for each non-operator child term in a chain and returns a list of maps
 * @param {QETerm} chain - a QETerm CHAIN term
 * @param {Object} util_options - used to pass through to getCharacterizedTermFactors
 * @returns {Object[]} child_term_factor_maps - a list of factor maps, each containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 */
function getChainChildTermFactorMaps(chain: QETerm, util_options): FactorMap[]{
	if (chain.type != "CHAIN") {
		console.log("Warning: called getChainChildTermFactorMaps on a non-chain term.");
		return [];
	}

	const child_term_factor_maps = [];
	for (let i = 0; i < chain.children.length; i += 2) {
		const child_term_factor_map = getCharacterizedTermFactors(chain.children[i], util_options);
		if (!child_term_factor_map) {
			console.log("ERROR: child term not happy");
			return;
		}

		child_term_factor_maps.push(child_term_factor_map);
	}
	return child_term_factor_maps;
}

/**
 * getCharacterizedTermFactors - recursively builds factor maps of term and its children
 * @param {QETerm} term - a QETerm node
 * @param {Object} util_options
 * @param {bool} [util_options.serialized_chain_factors] - flag to serialize ADD and MULTIPLY CHAINs as single string factors, not recursively factored
 *    - This is used for identifying and cancelling identical chains
 * @returns {Object} factors - map containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 */
export function getCharacterizedTermFactors(term: QETerm, util_options = {}){
	let factors: FactorMap = { num: {1: 1}, den: {1: 1} };

	if (term.type == "CHAIN") {
		if (term.isMultiplyChain()) {
			if (util_options['serialized_chain_factors']) {
				// treat entire serialized chain as a factor
				// multiplicative chain can safely strip leading "-" to canonicalize comparison
				factors.num[term.serialize_to_text().replace(/^-/, '')] = 1;
			} else {
				// get the factors for each child term
				const child_term_factor_maps = getChainChildTermFactorMaps(term, util_options);
				if (!child_term_factor_maps) {
					return; // abort!
				}
				factors = getSumOfCharacterizedTermListFactors([factors].concat(child_term_factor_maps));
			}
		} else if (term.isAddChain()) {
			if (util_options['serialized_chain_factors']) {
				// treat entire serialized chain as a factor
				factors.num[term.serialize_to_text()] = 1;
			} else {
				// get the factors for each child term
				const child_term_factor_maps = getChainChildTermFactorMaps(term, util_options);
				if (!child_term_factor_maps) {
					return; // abort!
				}
				factors = getIntersectionOfCharacterizedTermListFactors(child_term_factor_maps, util_options);

				// create a "leftover" factor string by finding the factor difference of each term in the chain, divided by the common factors

				// get the non-common "leftover" factors from each term
				const add_term_list = additiveChainToList(term);

				let leftover_chain_str = '';
				for (let i = 0; i < child_term_factor_maps.length; i++) {
					const difference_map = getDifferenceOfFactorMaps(child_term_factor_maps[i], factors);
					const leftover_term_str = generateTermFromFactors(difference_map).serialize_to_text();

					if (i) {
						// preceding operator
						leftover_chain_str += term.children[2 * i - 1].value;
					} else if (term.children[i].sign < 0) {
						// negative leading term
						leftover_chain_str += '-';
					}
					leftover_chain_str += leftover_term_str;
				}

				// leftover add chain terms constitute a serialized factor
				factors.num[leftover_chain_str] = 1;
			}
		} else {
			// TODO: EQUAL CHAIN should get factor intersection, as with ADD

			console.log("ERROR: unhandled chain type");
			return;
		}
	} else if (term.type == "RATIONAL") {
		if (util_options['serialized_chain_factors']) {
			// when serialized_chain_factors flag set we only want serialized chains, not individual factors that could be double-counted as a member of a chain
			return factors;
		}

		// "0" factor
		if (term.Q.num == 0) {
			factors.num["0"] = 1;
		}

		// getPrimeFactorDecomposition returns undefined when called on 0 or a negative value, and a single [1,1] when called on 1
		const num_primes = QESolverStep.getPrimeFactorDecomposition(Math.abs(term.Q.num));
		const den_primes = QESolverStep.getPrimeFactorDecomposition(term.Q.den);

		// convert prime factors to factor maps
		if (num_primes && typeof num_primes[0] == "object") {
			num_primes.forEach(function(factor_pair){
				factors.num[factor_pair[0]] = factor_pair[1];
			});
		}
		if (den_primes && typeof den_primes[0] == "object") {
			den_primes.forEach(function(factor_pair){
				factors.den[factor_pair[0]] = factor_pair[1];
			});
		}
	} else if (term.value == "frac") {
		// get numerator and denominator factors
		const num_factors = getCharacterizedTermFactors(term.children[0], util_options);
		const den_factors = getCharacterizedTermFactors(term.children[1], util_options);

		// TODO: are we discarding non_int_den? e.g. frac{1, pow{x,a}}

		// invert denominator factors and combine
		factors = getSumOfCharacterizedTermListFactors([num_factors, {num: den_factors.den, den: den_factors.num}]);
	} else if (term.type == "BRACKETS") {
		// BRACKETS pass through child factors
		return getCharacterizedTermFactors(term.children[0], util_options);
	} else if (term.type == "EXPONENT" || term.value == "pow") {
		// handle \pow{} and EXPONENT
		const base = term.children[0];
		const exponent = term.children[1];

		if (Number.isInteger(Number(exponent.value))) {
			// exponents multiply the factor exponents of child terms
			const base_factors = getCharacterizedTermFactors(base, util_options);

			// multiply each child factor exponent by exponent.value
			const exponent_value = Math.trunc(Number(exponent.value));
			for (let factor in base_factors.num) {
				if (factor == "1") continue;
				factors.num[factor] = base_factors.num[factor] * exponent_value;
			}
			for (let factor in base_factors.den) {
				if (factor == "1") continue;
				factors.den[factor] = base_factors.den[factor] * exponent_value;
			}
		} else {
			// record non-integer exponents in separate factor key maps: non_int_num, non_int_den

			// exponents multiply the factor exponents of child terms
			const base_factors = getCharacterizedTermFactors(base, util_options);

			// multiply each child factor exponent by exponent.value
			// TODO: check for negative, and invert?
			const exponent_value = exponent.serialize_to_text();

			factors.non_int_num = {};
			for (let factor in base_factors.num) {
				if (factor == "1") continue;
				factors.non_int_num[factor] = {};
				factors.non_int_num[factor][exponent_value] = 1;
			}

			factors.non_int_den = {};
			for (let factor in base_factors.den) {
				if (factor == "1") continue;
				factors.non_int_den[factor] = {};
				factors.non_int_den[factor][exponent_value] = 1;
			}
		}
	} else if (term.value == "sqrt" || term.value == "nroot") {
		// handle \sqrt{} and \nroot{}
		let base, root_index_value;

		if (term.value == "sqrt") {
			base = term.children[0];
			root_index_value = 2;
		} else {
			root_index_value = term.children[0].value;
			base = term.children[1];
		}

		// set base_factors for this root_index - reduction operations can only be performed on terms with common root_index
		const base_factors = getCharacterizedTermFactors(base, util_options);
		factors.root_index = {};
		factors.root_index[root_index_value] = base_factors;
	} else {
		if (util_options['serialized_chain_factors']) {
			// when serialized_chain_factors flag set we only want serialized chains, not individual factors that could be double-counted as a member of a chain
			return factors;
		}

		// use serialized value as the factor
		const serialized = term.serialize_to_text().replace(/^-/, '');
		factors.num[serialized] = 1;
	}

	return factors;
}

/**
 * filterFactorMapToRationalFactors - returns a factor map containing only the integer numerator and denominator factors
 * @param {FactorMap} factor_map - map containing sub-maps of term factors
 * @returns {FactorMap} filtered_map - containing "num" and "den" sub-maps with only numeric factors
 */
export function filterFactorMapToRationalFactors(factor_map: FactorMap): FactorMap {
	const filtered_map: FactorMap = { num: {1: 1}, den: {1: 1} };

	// keep only numeric factors
	Object.keys(factor_map.num || {}).
		filter(function(factor){
			return !Number.isNaN(Number(factor));
		}).forEach(function(factor){
			filtered_map.num[factor] = factor_map.num[factor];
		});

	Object.keys(factor_map.den || {}).
		filter(function(factor){
			return !Number.isNaN(Number(factor));
		}).forEach(function(factor){
			filtered_map.den[factor] = factor_map.den[factor];
		});

	return filtered_map;
}

/**
 * filterFactorMapToNonRationalFactors - returns a factor map containing only the non-integer numerator and denominator factors
 * @param {FactorMap} factor_map - map containing sub-maps of term factors
 * @returns {FactorMap} filtered_map - containing sub-maps with only non-numeric factors
 */
export function filterFactorMapToNonRationalFactors(factor_map: FactorMap): FactorMap {
	const filtered_map: FactorMap = { num: {1: 1}, den: {1: 1} };

	// keep only non-numeric factors
	Object.keys(factor_map.num || {}).
		filter(function(factor){
			return Number.isNaN(Number(factor));
		}).forEach(function(factor){
			filtered_map.num[factor] = factor_map.num[factor];
		});

	Object.keys(factor_map.den || {}).
		filter(function(factor){
			return Number.isNaN(Number(factor));
		}).forEach(function(factor){
			filtered_map.den[factor] = factor_map.den[factor];
		});

	// non_int_num, non_int_den exponents
	if (Object.keys(factor_map.non_int_num || {}).length ||
		Object.keys(factor_map.non_int_den || {}).length
	) {
		factor_map.non_int_num = {};
		factor_map.non_int_den = {};
	}
	Object.keys(factor_map.non_int_num || {}).forEach(function(factor){
		filtered_map.non_int_num[factor] = factor_map.non_int_num[factor];
	});
	Object.keys(factor_map.non_int_den || {}).forEach(function(factor){
		filtered_map.non_int_den[factor] = factor_map.non_int_den[factor];
	});

	// root_index factors
	if (factor_map.root_index) {
		// quick deep copy of root_index factors
		filtered_map.root_index = JSON.parse(JSON.stringify(factor_map.root_index));
	}

	return filtered_map;
}

/**
 * generateBaseKeyFromFactors - returns a serialized term from factors in the passed factor_map
 * @param {FactorMap} factor_map - map containing sub-maps of term factors
 * @returns {FactorMap} base_key - serialized term
 */
export function generateBaseKeyFromFactors(factor_map: FactorMap, options: {exclude_rational?: boolean} = {}): string {
	// build a base_num key from non-integer factors
	let base_num_key = Object.keys(factor_map.num || {}).sort().
		filter(function(factor){
			if (options.exclude_rational) {
				// fliter out integer factors
				return Number.isNaN(Number(factor));
			} else if (factor == "1") {
				// ignore "1" factor
				return false;
			} else {
				return true;
			}
		}).map(function(factor){
			const factor_count = factor_map.num[factor];
			return factor_count == 1 ? factor : '\\pow{'+ factor +','+ factor_count +'}';
		}).join('*');

	// include non_int_num components
	base_num_key += Object.keys(factor_map.non_int_num || {}).sort().
		map(function(factor){
			return Object.keys(factor_map.non_int_num[factor]).map(function(exp_factor){
				const factor_count = factor_map.non_int_num[factor][exp_factor];
				return factor_count == 1 ?
					'\\pow{'+ factor +','+ factor_count +'}' :
					'\\pow{'+ factor +',pow{'+ exp_factor +','+ factor_count +'}}';
			}).join('*');
		}).join('*');

	// root_index factors
	if (factor_map.root_index) {
		base_num_key += Object.keys(factor_map.root_index || {}).sort().
			map(function(root_index){
				return '\\nroot{'+ root_index +','+ generateBaseKeyFromFactors(factor_map.root_index[root_index]) +'}';
			}).join('*');
	}

	if (!base_num_key.length) {
		// if numerator empty, default to "1"
		base_num_key = "1";
	}

	// build a base_den key from non-integer factors
	let base_den_key = Object.keys(factor_map.den || {}).sort().
		filter(function(factor){
			if (options.exclude_rational) {
				// fliter out integer factors
				return Number.isNaN(Number(factor));
			} else if (factor == "1") {
				// ignore "1" factor
				return false;
			} else {
				return true;
			}
		}).map(function(factor){
			const factor_count = factor_map.den[factor];
			return factor_count == 1 ? factor : '\\pow{'+ factor +','+ factor_count +'}';
		}).join('*');

	// include non_int_den components
	base_den_key += Object.keys(factor_map.non_int_den || {}).sort().
		map(function(factor){
			return Object.keys(factor_map.non_int_den[factor]).map(function(exp_factor){
				const factor_count = factor_map.non_int_den[factor][exp_factor];
				return factor_count == 1 ?
					'\\pow{'+ factor +','+ factor_count +'}' :
					'\\pow{'+ factor +',pow{'+ exp_factor +','+ factor_count +'}}';
			}).join('*');
		}).join('*');

	// combined (num and den parts) base key
	let base_key = '';
	if (base_den_key.length) {
		base_key = '\\frac{'+ base_num_key +','+ base_den_key +'}';
	} else if (base_num_key.length && !base_den_key.length) {
		base_key = base_num_key;
	}

	return base_key;
}

/**
 * generateNonRationalBaseKeyFromFactors - returns a serialized term from only the non-integer factors in the passed factor_map
 * @param {FactorMap} factor_map - map containing sub-maps of term factors
 * @returns {FactorMap} base_key - serialized term
 */
export function generateNonRationalBaseKeyFromFactors(factor_map: FactorMap): string {
	return generateBaseKeyFromFactors(factor_map, {exclude_rational: true});
}

/**
 * cancelIdenticalFracTerms - cancels out an identical term in the numerator and denominator, and returns a new, reduced fraction
 *    - applies strikethrough highlighting to input frac
 * @param {QETerm} frac - a QETerm frac term
 * @param {Object} util_options
 * @param {QETerm} [util_options.specified_term] - a reference to the term to cancel out. This is an exact "===" match to a term in num_terms
 * @returns {Object} data
 * @returns {QETerm} data.new_frac - a new frac term, usable by the caller as a reduced replacement for frac
 * @returns {string} data.desc - Display string description of the transformation
 */
export function cancelIdenticalFracTerms(frac: QETerm, util_options){
	util_options ||= {};

	// TODO: allow util_options.specified_term to specify the exact term to cancel

	const num_terms = multiplicativeTermToList(frac.children[0]);
	const den_terms = multiplicativeTermToList(frac.children[1]);

	// identify "identical" terms with equivalent factor maps
	for (let i = 0; i < num_terms.length; i++) {
		const num_term = num_terms[i];
		const num_term_factors = getCharacterizedTermFactors(num_term, {});

		for (let j = 0; j < den_terms.length; j++) {
			const den_term = den_terms[j];
			const den_term_factors = getCharacterizedTermFactors(den_term, {});

			// identify "identical" terms with equivalent factor maps
			const equivalent = areFactorMapsEquivalent(num_term_factors, den_term_factors);
			if (!equivalent) {
				continue;
			}

			const desc = 'Simplify a fraction by cancelling out the common term '+ num_term.display();

			// create new numerator and denominator terms, excluding the cancelled terms
			const new_num_terms = [];
			for (let k = 0; k < num_terms.length; k++) {
				if (num_terms[k] !== num_term) {
					new_num_terms.push(num_terms[k]);
				}
			}
			const new_den_terms = [];
			for (let k = 0; k < den_terms.length; k++) {
				if (den_terms[k] !== den_term) {
					new_den_terms.push(den_terms[k]);
				}
			}

			const new_num = listToMultiplicativeTerm(new_num_terms);
			const new_den = listToMultiplicativeTerm(new_den_terms);

			// check if the denominator was totally cancelled
			let new_frac;
			if (!new_den_terms.length) {
				// denominator cancelled, keep numerator
				new_frac = new_num;
			} else {
				new_frac = QETerm.create({type: "FUNCTION", value: "frac"});
				new_frac.pushChild(new_num);
				new_frac.pushChild(new_den);
			}

			// retain sign;
			new_frac.sign = frac.sign;

			num_term.addClass("strikethrough_input");
			den_term.addClass("strikethrough_input");

			return {
				new_frac: new_frac,
				desc: desc,
			};
		}
	}

	// no identical terms cancelled
	return undefined;
}

/**
 * divideDecimalsInFracTerms - divides a numeric term out of the denominator, and returns a reduced fraction
 *    - applies strikethrough/input highlighting to input frac
 * @param {QETerm} frac - a QETerm frac term
 * @param {Object} [util_options]
 * @param {QETerm} [util_options.specified_term] - a reference to the term to cancel out. This is an exact "===" match to a term in num_terms
 * @param {bool} [util_options.force_rational_division] - a flag to force any numeric terms to be used, otherwise at least one argument must be a decimal number
 *    - overrides util_options.specified_term behaviour
 * @returns {Object} data
 * @returns {QETerm} data.new_frac - a new frac term, usable by the caller as a reduced replacement for frac
 * @returns {string} data.desc - Display string description of the transformation
 */
export function divideDecimalsInFracTerms(frac: QETerm, util_options){
	util_options ||= {};

	// TODO: allow util_options.specified_term to specify the exact term to cancel

	const num_terms = multiplicativeTermToList(frac.children[0]);
	const den_terms = multiplicativeTermToList(frac.children[1]);

	for (let i = 0; i < num_terms.length; i++) {
		const num_term = num_terms[i];
		if (num_term.type != "RATIONAL") {
			continue;
		}

		for (let j = 0; j < den_terms.length; j++) {
			const den_term = den_terms[j];
			if (den_term.type != "RATIONAL") {
				continue;
			}

			// do rational division if either term is a decimal or if force_rational_division flag set
			if (util_options.force_rational_division || num_term.attr('value_type') == 'decimal' || den_term.attr('value_type') == 'decimal'){
				if (den_term.Q.isOne()) {
					// den_term == "1" - no point dividing
					continue;
				}

				const new_Q = num_term.Q.divide(den_term.Q);
				const new_decimal = new_Q.to_term({value_type: 'decimal'});

				const desc = 'Divide numeric terms in a fraction';

				// create new numerator and denominator terms, excluding cancelled terms
				const new_num_terms = [];
				for (let k = 0; k < num_terms.length; k++) {
					if (num_terms[k] === num_term) {
						if (new_decimal.Q.isOne()) {
							// num_term was cancelled entirely
							num_term.addClass("strikethrough_input");
						} else {
							num_term.addClass("highlight_input");
							new_decimal.addClass("highlight_output");
							new_num_terms.push(new_decimal);
						}
					} else {
						new_num_terms.push(num_terms[k]);
					}
				}
				const new_den_terms = [];
				for (let k = 0; k < den_terms.length; k++) {
					if (den_terms[k] === den_term) {
						den_term.addClass("strikethrough_input");
					} else {
						new_den_terms.push(den_terms[k]);
					}
				}

				const new_num = listToMultiplicativeTerm(new_num_terms);
				const new_den = listToMultiplicativeTerm(new_den_terms);

				if (!new_num_terms.length) {
					new_num.addClass("highlight_output");
				}

				// check if the denominator was totally cancelled
				let new_frac;
				if (!new_den_terms.length) {
					// denominator cancelled, keep numerator
					new_frac = new_num;
				} else {
					new_frac = QETerm.create({type: "FUNCTION", value: "frac"});
					new_frac.pushChild(new_num);
					new_frac.pushChild(new_den);
				}

				// retain sign;
				new_frac.sign = frac.sign;

				return {
					new_frac: new_frac,
					desc: desc,
				};
			}
		}
	}

	// no identical terms cancelled
	return undefined;
}

/**
 * dividePowersInFracTerm - divides a power term out of the denominator, and returns a reduced fraction
 *    - applies strikethrough/input highlighting to input frac
 * @param {QETerm} frac - a QETerm frac term
 * @param {Object} [util_options]
 * @param {QETerm} [util_options.specified_term] - a reference to the term to cancel out. This is an exact "===" match to a term in num_terms
 * @returns {Object} data
 * @returns {QETerm} data.new_frac - a new frac term, usable by the caller as a reduced replacement for frac
 * @returns {string} data.desc - Display string description of the transformation
 */
export function dividePowersInFracTerm(frac: QETerm, util_options){
	util_options ||= {};

	// TODO: allow util_options.specified_term to specify the exact term to cancel

	const num_terms = multiplicativeTermToList(frac.children[0]);
	const den_terms = multiplicativeTermToList(frac.children[1]);

	// TODO: single-pass num and den terms to avoid re-factoring den terms multiple times

	for (let i = 0; i < num_terms.length; i++) {
		const num_term = num_terms[i];
		const num_term_factors = getCharacterizedTermFactors(num_term, {});

		let num_term_base;
		if (num_term.type == "EXPONENT" || num_term.value == "pow") {
			num_term_base = num_term.children[0];
		} else {
			num_term_base = num_term;
		}
		const num_term_base_key = num_term_base.serialize_to_text();

		for (let j = 0; j < den_terms.length; j++) {
			const den_term = den_terms[j];
			const den_term_factors = getCharacterizedTermFactors(den_term, {});

			let den_term_base_key;
			if (den_term.type == "EXPONENT" || den_term.value == "pow") {
				den_term_base_key = den_term.children[0].serialize_to_text();
			} else {
				den_term_base_key = den_term.serialize_to_text();
			}

			if (den_term_base_key != num_term_base_key) {
				continue;
			}

			const desc = 'Divide powers with the same base using the quotient rule of exponents: <katex>\\frac{a^m}{a^n} = a^{m-n}</katex>';

			// TODO: check if num and den terms have only integer exponents - if so, perform exponent reduction in one step

			// create new exponent from numerator term exponent minus denominator term exponent
			let new_num_term_exponent_terms = [];
			if (num_term.type == "EXPONENT" || num_term.value == "pow") {
				const num_term_exponent_terms = additiveChainToList(num_term.children[1]);
				for (let j = 0; j < num_term_exponent_terms.length; j++) {
					new_num_term_exponent_terms.push(num_term_exponent_terms[j]);
				}
			} else {
				new_num_term_exponent_terms.push(QETerm.create({type: "RATIONAL", value: "1"}));
			}

			// subtract denominator exponent
			if (den_term.type == "EXPONENT" || den_term.value == "pow") {
				const den_term_exponent_terms = additiveChainToList(den_term.children[1]);
				for (let j = 0; j < den_term_exponent_terms.length; j++) {
					const den_term_exponent_term_clone = den_term_exponent_terms[j].clone();
					den_term_exponent_term_clone.negate();
					new_num_term_exponent_terms.push(den_term_exponent_term_clone);
				}
			} else {
				const den_term_exponent_term_clone = QETerm.create({type: "RATIONAL", value: "1"});
				den_term_exponent_term_clone.negate();
				new_num_term_exponent_terms.push(den_term_exponent_term_clone);
			}
			const new_num_term_exponent = listToAdditiveChain(new_num_term_exponent_terms);

			// create new term from base and new exponent
			const new_num_term = QETerm.create({type: "FUNCTION", value: "pow"});
			// TODO: strip sign?
			new_num_term.pushChild(num_term_base.clone());
			new_num_term.pushChild(new_num_term_exponent);

			new_num_term.addClass("highlight_output");

			// create new numerator and denominator terms, excluding cancelled terms
			const new_num_terms = num_terms.slice(0, i).concat(new_num_term, num_terms.slice(i+1));
			const new_den_terms = den_terms.slice(0, j).concat(den_terms.slice(j+1));
			const new_num = listToMultiplicativeTerm(new_num_terms);
			const new_den = listToMultiplicativeTerm(new_den_terms);

			num_term.addClass("highlight_input");
			den_term.addClass("highlight_input");
			den_term.addClass("strikethrough_input");

			// check if the denominator was totally cancelled
			let new_frac;
			if (!new_den_terms.length) {
				// denominator cancelled, keep numerator
				new_frac = new_num;
			} else {
				new_frac = QETerm.create({type: "FUNCTION", value: "frac"});
				new_frac.pushChild(new_num);
				new_frac.pushChild(new_den);
			}

			// retain sign;
			new_frac.sign = frac.sign;

			return {
				new_frac: new_frac,
				desc: desc,
			};
		}
	}

	// no terms cancelled
	return undefined;
}

/**
 * replaceTargetTermInListWithProductOfFactors - helper to replace a target term in a list of terms with two multiplicand terms
 *    - target term is split into "common" and "leftover" terms
 * @param {QETerm[]} terms_list - list of QETerms including target_term, used for reconstituting an updated chain term after target_term replacement
 * @param {QETerm} target_term - the "target" term to be split into "common" and "leftover" terms
 * @param {Object[]} term_factors - factor map of target_term containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 * @param {QETerm} common_term - the "common" term
 * @param {Object[]} common_factors - factor map of common_term containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 * @returns {QETerm} new_chain - the new term list, including "common" and "leftover" terms, converted to a multiply chain term
 */
function replaceTargetTermInListWithProductOfFactors(terms_list: QETerm[], target_term: QETerm, term_factors: FactorMap, common_term: QETerm, common_factors: FactorMap) {
	// get "leftover" term by reconstituting a term from term_factors, excluding (dividing out by factor subtraction) common_factors
	const leftover_term_factors = getDifferenceOfFactorMaps(term_factors, common_factors);
	const leftover_term = generateTermFromFactors(leftover_term_factors);

	// check if everything factored out of num_term, leaving just "1"
	const new_terms = [];
	let new_chain;
	if (leftover_term.value != "1") {
		// target_term has been factored. Create new multiplicative term, with target_term replaced by common_term * leftover_term
		for (let k = 0; k < terms_list.length; k++) {
			if (terms_list[k] === target_term) {
				new_terms.push(common_term);

				leftover_term.addClass("highlight_output");
				new_terms.push(leftover_term);
			} else {
				new_terms.push(terms_list[k]);
			}
		}
		new_chain = listToMultiplicativeTerm(new_terms);

		target_term.addClass("highlight_input");
	} else {
		// common_term equal to target_term
		for (let k = 0; k < terms_list.length; k++) {
			if (terms_list[k] === target_term) {
				new_terms.push(common_term);
			} else {
				new_terms.push(terms_list[k]);
			}
		}
		new_chain = listToMultiplicativeTerm(new_terms);

		target_term.addClass("highlight_input")
	}

	return new_chain;
}

/**
 * factorOutCommonFracFactors - finds a numerator term and denominator term with a common factor, and converts the terms to products of the common factors and leftover factors
 *    - applies strikethrough/input highlighting to input frac
 * @param {QETerm} frac - a QETerm frac term
 * @param {Object} util_options
 * @param {bool} [util_options.use_decimal_den_2_5_padding] - flag to enable decimal padding of "2" and "5" denominator factor counts for decimal comparisons
 *    - passthrough to getIntersectionOfCharacterizedTermListFactors
 * @param {bool} [util_options.force_rational_division] - a flag to force any numeric terms to be used, otherwise at least one argument must be a decimal number
 *    - passthrough to generateTermFromFactors
 * @returns {Object} data
 * @returns {QETerm} data.new_frac - a new frac term, usable by the caller as a reduced replacement for frac
 * @returns {string} data.desc - Display string description of the transformation
 */
export function factorOutCommonFracFactors(frac: QETerm, util_options){
	// factorable cases:
	// 1. non-decimal rationals
	// 2. powers (including power "1") with same base
	// 3. roots with same root_index and common factors in bases
	// if a term is an additive chain, a common factor must be found in each term of the chain
	const num_terms = multiplicativeTermToList(frac.children[0]);
	const den_terms = multiplicativeTermToList(frac.children[1]);

	// check if num_term or den_term contains any RATIONALs with value_type: "decimal"
	// - use to control whether we should "use_decimal_den_2_5_padding" when finding common factors, and treat "rationals_as_decimals" when generating a common_term from factors
	// - can be overridden by util_options
	const fraction_contains_decimals = frac.findAllChildren("type", "RATIONAL").filter(function(term){ return term.attr("value_type") == "decimal"; }).length > 0;

	// NOTE: rather than use_decimal_den_2_5_padding, we should possibly use a flag to include all denominator term rational when dividing out.
	// - This behaviour would be more consistent with divideDecimalsInFracTerms, where presence of a decimal leads to full division of decimals in denominator, not just the common portion.

	// TODO: optimization: cache den_term_factors from getCharacterizedTermFactors so we don't calculate again for each num_term pass

	for (let i = 0; i < num_terms.length; i++) {
		const num_term = num_terms[i];
		const num_term_factors = getCharacterizedTermFactors(num_term, {});

		for (let j = 0; j < den_terms.length; j++) {
			const den_term = den_terms[j];
			const den_term_factors = getCharacterizedTermFactors(den_term, {});

			// find intersection of factors
			const common_factors = getIntersectionOfCharacterizedTermListFactors([num_term_factors, den_term_factors], Object.assign({use_decimal_den_2_5_padding: fraction_contains_decimals}, util_options));

			if (!(
				Object.keys(common_factors.num).length != 1 ||
				Object.keys(common_factors.den).length != 1 ||
				common_factors.non_int_num || common_factors.non_int_den || common_factors.root_index
			)) {
				// nothing common to factor out
				continue;
			}

			// create term from factors
			const common_term = generateTermFromFactors(common_factors, Object.assign({rationals_as_decimals: fraction_contains_decimals}, util_options));

			const desc = 'Factor out the common term '+ common_term.display() +' in the fraction so it can be cancelled out.';
			common_term.addClass("highlight_output"); // highlight after display() to prevent highlgihting in desc

			// generate new numerator and denominator with the common term factored out of num_term and den_term
			const new_numerator = replaceTargetTermInListWithProductOfFactors(num_terms, num_term, num_term_factors, common_term, common_factors);
			const new_denominator = replaceTargetTermInListWithProductOfFactors(den_terms, den_term, den_term_factors, common_term, common_factors);

			// check if the denominator was totally cancelled
			let new_frac;
			if (new_denominator.type == "RATIONAL" && new_denominator.Q.isOne()) {
				// denominator cancelled, keep numerator
				new_frac = new_numerator;
			} else {
				new_frac = QETerm.create({type: "FUNCTION", value: "frac"});
				new_frac.pushChild(new_numerator);
				new_frac.pushChild(new_denominator);
			}

			// retain sign;
			new_frac.sign = frac.sign;

			return {
				new_frac: new_frac,
				desc: desc,
			};
		}
	}

	// no common factors pulled out
	return undefined;
}

/**
 * createDividedTerm - produces a fraction quotient from a dividend and divisor term - does not reduce, only creates the combined term
 * @param {QETerm} dividend - a QETerm single term, chain, or frac
 * @param {QETerm} divisor - a QETerm single term, chain, or frac
 * @returns {QETerm} new_frac - a new frac term, generated from dividend / divisor
 */
export function createDividedTerm(dividend: QETerm, divisor: QETerm) {
	// extract and merge sign from elements where it gets stripped (frac, multiply chain)
	let sign = 1;

	// create numerator and denominator multiplicate term lists for dividend and divisor
	let dividend_num_terms;
	let dividend_den_terms = [];

	// retain sign, is stripped by multiplicativeTermToList on frac and multiply chain
	sign = sign * (dividend.sign || 1);

	if (dividend.value == "frac") {
		dividend_num_terms = multiplicativeTermToList(dividend.children[0]);
		dividend_den_terms = multiplicativeTermToList(dividend.children[1]);
	} else if (dividend.isMultiplyChain()) {
		dividend_num_terms = multiplicativeTermToList(dividend);
	} else {
		// strip sign - we've already retained it
		dividend.sign = 1;

		dividend_num_terms = multiplicativeTermToList(dividend);
	}

	let divisor_num_terms;
	let divisor_den_terms = [];

	// retain sign, is stripped by multiplicativeTermToList on frac and multiply chain
	sign = sign * (divisor.sign || 1);

	if (divisor.value == "frac") {
		divisor_num_terms = multiplicativeTermToList(divisor.children[0]);
		divisor_den_terms = multiplicativeTermToList(divisor.children[1]);
	} else if (divisor.isMultiplyChain()) {
		divisor_num_terms = multiplicativeTermToList(divisor);
	} else {
		// strip sign - we've already retained it
		divisor.sign = 1;

		divisor_num_terms = multiplicativeTermToList(divisor);
	}

	// flip divisor and create new multiplicative chain terms for new_frac numerator and denominator
	const new_numerator = listToMultiplicativeTerm(dividend_num_terms.concat(divisor_den_terms));
	const new_deonominator = listToMultiplicativeTerm(dividend_den_terms.concat(divisor_num_terms));

	const new_frac = QETerm.create({type: "FUNCTION", value: "frac"});
	new_frac.pushChild(new_numerator);
	new_frac.pushChild(new_deonominator);

	new_frac.sign = sign;

	return new_frac;
}

/**
 * parseToChainModeTerm - helper to parse a string to a term, and convert any binary ops to chains
 * @param {string} str - the math expression string to parse
 * @param {Object} [util_options] - passthrough to QESolverStep.convertToChains
 * @returns {QETerm} term - a new QETerm
 */
export function parseToChainModeTerm(str: string, util_options?) {
	let term = tokenize_and_parse(str, { merge_sign_operators: true }).tree;

	return term.children[0];
}

/**
 * generateTermFromFactors - generate a QETerm term from factor maps
 * - the primary use for this is to reconstitute a term after it has been reduced by dividing out factors
 * @param {Object} factors - a map containing sub-maps of "num" and "den" factors, and possibly "non_int_num", "non_int_den", and "root_index" factors
 * @param {Object} [util_options]
 * @param {bool} [util_options.rationals_as_decimals] - flag to indicate any denominator coefficient should be divided into the numerator coefficient
 * @returns {QETerm} term - new QETerm created from the factor map
 */
export function generateTermFromFactors(factors: FactorMap, util_options: {rationals_as_decimals?: boolean} = {rationals_as_decimals: false}){

	// generate numerator terms from factors
	let num_coeff = 1;
	const num_terms = [];

	// numerator integer exponents
	Object.keys(factors.num).forEach(function(factor){
		if (Number.isInteger(Number(factor))) {
			// integer factor
			num_coeff *= Math.pow(Number(factor), factors.num[factor]);
		} else {
			// parse non-numeric factors to term node
			if (factors.num[factor] == 1) {
				const term = parseToChainModeTerm(factor);
				num_terms.push(term);
			} else {
				const term = parseToChainModeTerm('\\pow{'+ factor +','+ factors.num[factor] +'}');
				num_terms.push(term);
			}
		}
	});

	// TODO: if a term is an add chain, we should set it aside, and then multiply the other terms with each term in the add chain
	// e.g. "2" and "x+1" should become "2x+2" rather than "2(x+1)"

	// numerator non-integer exponents
	Object.keys(factors.non_int_num || {}).forEach(function(factor){
		const term = parseToChainModeTerm('\\pow{'+ factor +','+ factors.non_int_num[factor] +'}');
		num_terms.push(term);
	});

	// roots
	Object.keys(factors.root_index || {}).forEach(function(root_index){
		// generate the base from the nested factors for this root_index
		const base_term = generateTermFromFactors(factors.root_index[root_index]);

		// check that base_term != "1"
		if (base_term.type == "RATIONAL" && base_term.Q.isOne() && base_term.sign != -1) {
			// do nothing, any root of "1" is "1"
		} else {
			let root_term;
			if (root_index == "2") {
				root_term = QETerm.create({type:"FUNCTION", value:"sqrt"});
			} else {
				root_term = QETerm.create({type:"FUNCTION", value:"nroot"});
				root_term.pushChild(QETerm.create({type:"RATIONAL", value: root_index}));
			}
			root_term.pushChild(base_term);

			num_terms.push(root_term);
		}
	});

	// generate denominator terms from factors
	let den_coeff = 1;
	let den_terms = [];

	// denominator integer exponents
	Object.keys(factors.den).forEach(function(factor){
		if (Number.isInteger(Number(factor))) {
			den_coeff *= Math.pow(Number(factor), factors.den[factor]);
		} else {
			// parse non-numeric factors to term node
			if (factors.den[factor] == 1) {
				const term = parseToChainModeTerm(factor);
				den_terms.push(term);
			} else {
				const term = parseToChainModeTerm('\\pow{'+ factor +','+ factors.den[factor] +'}');
				den_terms.push(term);
			}
		}
	});
	// denominator non-integer exponents
	Object.keys(factors.non_int_den || {}).forEach(function(factor){
		const term = parseToChainModeTerm('\\pow{'+ factor +','+ factors.non_int_den[factor] +'}');
		den_terms.push(term);
	});

	// construct coefficient terms and move to the start of the term lists
	if (util_options.rationals_as_decimals) {
		// if "rationals_as_decimals" flag set, move the denominator coefficient to the numerator
		if (den_coeff != 1) {
			const coeff_Q = new QEQ(num_coeff, den_coeff);
			const num_coeff_term = coeff_Q.to_term({"value_type": "decimal"});
			num_terms.unshift(num_coeff_term);
		} else {
			// no denominator coefficient
			const num_coeff_term = QETerm.create({type:"RATIONAL", value: num_coeff.toString()});
			num_terms.unshift(num_coeff_term);
		}
	} else {
		if (num_coeff != 1) {
			const num_coeff_term = QETerm.create({type:"RATIONAL", value: num_coeff.toString()});
			num_terms.unshift(num_coeff_term);
		}
		if (den_coeff != 1) {
			const den_coeff_term = QETerm.create({type:"RATIONAL", value: den_coeff.toString()});
			den_terms.unshift(den_coeff_term);
		}
	}

	let frac;
	const numerator = listToMultiplicativeTerm(num_terms);
	if (den_terms.length) {
		const denominator = listToMultiplicativeTerm(den_terms);

		frac = QETerm.create({type: "FUNCTION", value: "frac"});
		frac.pushChild(numerator);
		frac.pushChild(denominator);
	} else {
		frac = numerator;
	}
	return frac;
}

/**
 * multiplicativeTermToList - converts a single term or multiplicative CHAIN to an array of terms
 *    NOTE: if the term is a multiplicative CHAIN, the term sign is not included in the list, and must be tracked separately
 * @param {QETerm} term - a QETerm. If a multiply chain, then the list of non-operator children will be returned, else a single item list
 * @returns {QETerm[]} terms - list of one or more QETerm terms
 */
export function multiplicativeTermToList(term: QETerm) {
	// build list of terms
	const terms = [];
	if (term.isMultiplyChain()) {
		for (let j = 0; j < term.children.length; j+=2) {
			// abort step entirely if DIVIDE found - term is not a purely multiplicative CHAIN
			if (j > 0 && term.children[j-1].type == "DIVIDE") {
				return undefined;
			}

			// build list of terms
			terms.push(term.children[j]);
		}
	} else {
		terms.push(term);
	}
	return terms;
}

/**
 * listToMultiplicativeTerm - converts an array of terms to a single term (if list length == 1) or a multiplicative CHAIN (or "1", if empty)
 *    NOTE: this results in a NEW term containing new or cloned terms. Add chain list items are wrapped in brackets
 *    - sign must be handled by the calling code, since this function has no information about whether the sign of a term pushed onto the list is already accounted for
 *      cloned terms have sign stripped (set to positive 1)
 * @param {QETerm[]} list - list of one or more QETerm terms
 * @returns {QETerm} term - a QETerm constructed from list terms
 */
export function listToMultiplicativeTerm(list: QETerm[]) {
	if (list.length == 0) {
		return QETerm.create({type:"RATIONAL", value: "1"});
	} else if (list.length == 1) {
		const clone_term = list[0].clone();
		clone_term.sign = 1; // strip negative signs

		return clone_term;
	} else {
		// new CHAIN
		const chain = QETerm.create({type:"CHAIN", precedence: findGrammar("MULTIPLY").precedence});

		// flatten list: convert any multiplyChain members to lists
		let flat_list = [];
		for (let i = 0; i < list.length; i++) {
			if (list[i].isMultiplyChain()) {
				// note: this results in the sign of the multiply chain item being ignored
				flat_list = flat_list.concat(multiplicativeTermToList(list[i]));
			} else {
				flat_list.push(list[i]);
			}
		}

		// begin the chain with the first list term
		let clone_term = flat_list[0].clone();
		clone_term.sign = 1; // strip negative signs

		if (clone_term.isAddChain()) {
			// wrap in brackets if list.length > 1 - otherwise there's no need
			const brackets = QETerm.create({type:"BRACKETS"});
			brackets.pushChild(clone_term);

			chain.pushChild(brackets);
		} else {
			chain.pushChild(clone_term);
		}

		for (let i = 1; i < flat_list.length; i++) {
			clone_term = flat_list[i].clone();
			clone_term.sign = 1; // strip negative signs

			chain.pushChild(QETerm.create({ type:"MULTIPLY", value: "" }));

			if (clone_term.isAddChain()) {
				// wrap in brackets
				const brackets = QETerm.create({type:"BRACKETS"});
				brackets.pushChild(clone_term);

				chain.pushChild(brackets);
			} else {
				chain.pushChild(clone_term);
			}
		}

		return chain;
	}
}

/**
 * additiveChainToList - converts a term or additive CHAIN to an array of terms
 *    NOTE: this returns the non-operator children directly and does NOT merge in preceding operators
 *    - This means that when using the terms from the resultant list, the preceding operator (add/subtract) must be checked for all items where index > 0
 * TODO: make use of "preceding_sign" and "preceding_operator" in solver steps
 *     - allows non-destructive usage of preceding operators without requiring extra checks
 * @param {QETerm} term - a QETerm. If an add chain, then the list of non-operator children will be returned, else a single item list
 * @returns {QETerm[]} terms - list of one or more QETerm terms.
 */
export function additiveChainToList(term: QETerm) {
	// build list of terms
	const terms = [];
	if (term.isAddChain()) {
		for (let j = 0; j < term.children.length; j+=2) {
			let child_term = term.children[j];

			// retain child term sign and preceding op (if any)
			if (!j) {
				child_term.preceding_sign = child_term.sign || 1;
				child_term.preceding_operator = null;
			} else {
				let prev_op = child_term.prev();
				if (prev_op.type == "SUBTRACT") {
					child_term.preceding_sign = -1;
				} else {
					child_term.preceding_sign = 1;
				}
				child_term.preceding_operator = prev_op;
			}

			// build list of terms
			terms.push(child_term);
		}
	} else {
		// retain term sign and preceding op (if any)
		term.preceding_sign = term.sign || 1;
		term.preceding_operator = null;

		terms.push(term);
	}
	return terms;
}

/**
 * listToAdditiveChain - converts an array of terms to a single term (if list length == 1) or an additive CHAIN (or "0", if empty)
 *    NOTE: this results in a NEW term containing new or cloned terms
 *    - it does not check preceding operators (add/subtract), so any preceding operator signs must already be applied to each term
 * TODO: make use of "preceding_sign" and "preceding_operator" in solver steps
 *     - allows non-destructive usage of preceding operators without requiring extra checks
 * @param {QETerm[]} list - list of one or more QETerm terms
 * @returns {QETerm} term - a QETerm constructed from list terms
 */
export function listToAdditiveChain(list: QETerm[]) {
	if (list.length == 0) {
		return QETerm.create({type:"RATIONAL", value: "0"});
	} else if (list.length == 1) {
		const clone = list[0].clone();
		if (list[0].preceding_sign) {
			// use preceding_sign to set sign of clone
			clone.sign = list[0].preceding_sign;
		}
		return clone;
	} else {
		// new CHAIN
		const chain = QETerm.create({type:"CHAIN", precedence: findGrammar("ADD").precedence});

		// flatten list: convert any addChain members to lists
		let flat_list = [];
		for (let i = 0; i < list.length; i++) {
			if (list[i].isAddChain()) {
				flat_list = flat_list.concat(additiveChainToList(list[i]));
			} else {
				flat_list.push(list[i]);
			}
		}

		// begin the chain with the first list term
		let clone_term = flat_list[0].clone();
		if (flat_list[0].preceding_sign) {
			// use preceding_sign to set sign of clone
			clone_term.sign = flat_list[0].preceding_sign;
		}
		chain.pushChild(clone_term);

		for (let i = 1; i < flat_list.length; i++) {
			clone_term = flat_list[i].clone();
			if (flat_list[i].preceding_sign) {
				// use preceding_sign to set sign of clone
				clone_term.sign = flat_list[i].preceding_sign;
			}

			// TODO: apply any input/output classes to preceding operators as well

			if (clone_term.sign == -1) {
				// flip negative term signs and merge with preceding operator
				chain.pushChild(QETerm.create({ type:"SUBTRACT" }));
				clone_term.negate();
			} else {
				chain.pushChild(QETerm.create({ type:"ADD" }));
			}

			chain.pushChild(clone_term);
		}

		return chain;
	}
}

/**
 * sortMultiplicativeTermList - lexically sorts a multiplicative list of terms - returns undefined if already sorted, else an array of index values
 * @param {QETerm[]} list - list of QETerm terms
 * @returns {integer[]} sorted_indexes_list - a list of index values, referencing the terms in input list
 */
export function sortMultiplicativeTermList(list: QETerm[]) {
	// sort by term type, then alphabetically:
	// - rational
	// - constant
	// - numeric frac
	// - power w/ numeric base and exp
	// - radical w/ numeric base and root index
	// - brackets w/ numeric child
	// - function w/ numeric args
	// - variable (or power|radical w/ variable base)
	// - function w/ non-numeric args
	function lexClassify(term: QETerm){
		const is_numeric = term.findAllChildren("type", "VARIABLE").length ? false : true;

		// rationals
		if (term.type == "RATIONAL") {
			return {score: 0, value: term.evaluate_to_float()};
		} else if (term.type == "CONSTANT") {
			return {score: 1, value: term.evaluate_to_float()};
		} else if (term.value == "frac" && is_numeric) {
			return {score: 2, value: term.evaluate_to_float()};
		} else if ((term.type == "EXPONENT" || term.value == "pow") && is_numeric) {
			return {score: 3, value: term.evaluate_to_float()};
		} else if (term.value == "sqrt" && is_numeric) {
			return {score: 4, value: term.evaluate_to_float()};
		} else if (term.value == "nroot" && is_numeric) {
			return {score: 4, value: term.evaluate_to_float()};
		} else if (term.type == "BRACKETS" && is_numeric) {
			return {score: 5, value: term.evaluate_to_float()};
		} else if (term.type == "FUNCTION" && is_numeric) {
			return {score: 6, value: term.evaluate_to_float()};

		// variables and powers/roots of variables
		} else if (term.type == "VARIABLE") {
			return {score: 7, value: term.value};
		} else if (term.type == "EXPONENT" && term.children[0].type == "VARIABLE") {
			return {score: 7, value: term.children[0].value};
		} else if (term.value == "pow" && term.children[0].type == "VARIABLE") {
			return {score: 7, value: term.children[0].value};
		} else if (term.value == "nroot" && term.children[1].type == "VARIABLE") {
			return {score: 7, value: term.children[1].value};
		} else if (term.value == "sqrt" && term.children[0].type == "VARIABLE") {
			return {score: 7, value: term.children[0].value};
		}
		// everything else
		return {score: 3, value: term.serialize_to_text()};
	}

	// first pass: score terms for sorting
	const scored_list = list.map(function(term, index){
		return Object.assign({ index: index }, lexClassify(term));
	});

	// sort
	const presort = scored_list.map(function(x){ return x.index; }).join(',');
	scored_list.sort(function(a_score, b_score){
		if (a_score.score < b_score.score) {
			return -1;
		} else if (a_score.score > b_score.score) {
			return 1;
		} else if (a_score.score > 0 && a_score.score == b_score.score) {
			if (a_score.value < b_score.value) {
				return -1;
			} else if (a_score.value > b_score.value) {
				return 1;
			}
		}
		return 0;
	});
	const postsort = scored_list.map(function(x){ return x.index; }).join(',');

	if (presort != postsort) {
		return scored_list.map(function(x){ return x.index; });
	} else {
		return undefined;
	}
}

/**
 * sortAdditiveTermList - lexically sort an additive list of terms - returns undefined if already sorted, else an array of index values
 * @param {QETerm[]} list - list of QETerm terms
 * @returns {integer[]} sorted_indexes_list - a list of index values, referencing the terms in input list
 */
export function sortAdditiveTermList(list: QETerm[]) {
	// sort by term type rational, power|radical w/ rational base, variable (or power|radical w/ variable base), function (any)

	// NOTE: sort terms by:
	// - primary criteria:
	// 1. has-variable
	//   1a. lowest lexical value first: a < x < y < z
	//   1b. highest exponent
	// 2. rational
	// 3. Everything else: lexically sorted by serialized_to_text
	// e.g. "5 + 2y - x + z^2 + y*sin(x) - cos(x)"  ->   "-x + 2y + y*sin(x) + z^2 + 5 - cos(x)

	// Fractions? Classify the numerator and denominator, then invert exponent values for denominator vars

	// NOTE: additive CHAINs would be easier to handle if they were addition-only, and any signs were purely owned by terms

	function lexClassify(add_term_info){
		if (Object.keys(add_term_info.vars).length) {
			// has one or more variables: get lowest variable letter and its exponent
			const lowest_letter = Object.keys(add_term_info.vars).sort()[0];
			return {score: 0, value: lowest_letter, exponent: add_term_info.vars[lowest_letter] };
		} else if (add_term_info.serial_non_poly.length) {
			// non variable, non-rational
			return {score: 2, value: add_term_info.serial_non_poly};
		} else {
			// rational
			return {score: 1, value: ""};
		}
	}

	// first pass: score terms for sorting
	const scored_list = list.map(function(term, index){
		return Object.assign({ index: index }, lexClassify(term.characterization.add_term_info));
	});

	// sort
	const presort = scored_list.map(function(x){ return x.index; }).join(',');
	scored_list.sort(function(a_score, b_score){
		if (a_score.score < b_score.score) {
			return -1;
		} else if (a_score.score > b_score.score) {
			return 1;
		} else if (a_score.score == 0) {
			// term contains variable. Order by variable letter and exponent
			if (a_score.value < b_score.value) {
				return -1;
			} else if (a_score.value > b_score.value) {
				return 1;
			} else {
				// variable letters the same. Order by exponent, descending
				if (a_score.exponent > b_score.exponent) {
					return -1;
				} else if (a_score.value < b_score.value) {
					return 1;
				}
			}
		} else if (a_score.score == 2) {
			// non variable, non-rational
		}

		return 0;
	});
	const postsort = scored_list.map(function(x){ return x.index; }).join(',');

	if (presort != postsort) {
		return scored_list.map(function(x){ return x.index; });
	} else {
		return undefined;
	}
}

export function helper_fractionDenominatorsLCM(input_value, options) {
	if (input_value.type != "tree") return undefined;

	// Validate tree contains a list with at least 2 items
	if (
		input_value.value.children[0].type != "FUNCTION" ||
		input_value.value.children[0].value != "list" ||
		input_value.value.children[0].children.length != 2
	) {
		return undefined;
	}
	var old_term = input_value.value.children[0];
	var terms = old_term.children;

	// find the denominators present in all
	var denominators = [];
	for (var j = 0; j < terms.length; j++) {
		if (terms[j].value == "frac") {
			denominators.push(terms[j].children[1].value);
		}
	}

	var lcm = undefined;
	if (denominators.length == 0) {
		return undefined;
	} else if (denominators.length == 1) {
		lcm = denominators[0];
	} else {
		let denominator_list = tokenize_and_parse(
			"list{" + denominators.join(",") + "}",
			{ merge_sign_operators: true }
		).tree;
		let result = QESolver.solveUsing("get_lcm_by_gcf", {
			type: "tree",
			value: denominator_list,
		});
		lcm = result.value.serialize_to_text();
	}

	return lcm;
}


//Takes list of (x,y) pairs in any order, in form [{x:1, y:2 }, {x:3, y:4}]
//returns a list of values sorted such that they form a simple polygon, that is a polygon that never inserts itself.
//Technique: take leftmost and rightmost points l and r, drawing a line connecting l and r, sorting all remaining points.. 
//into either points above the line, A, or below the line, B. Sort A from left to right, and B from right to left. Return [l, A, B, r].
export function SimplePolygon(input_list) {
	// helper function to sort objects of form {x: a, y: b} from left to right
	function sortLeftToRight(a, b) {
		if(a.x < b.x)
			return -1;
		if(a.x > b.x)
			return 1;
		if(a.y < b.y)
			return 1;
		if(a.y > b.y)
			return -1;
		console.warn("duplicate points in polygon");
		return 0;
	}
	let unsorted_list = input_list.slice(0);
	for(let i = 0; i < unsorted_list.length; i++) {
		let xfound = false;
		let yfound = false;
		for(let curr in unsorted_list[i])
		{
			//Replace any string numbers with numbers
			//Return an empty array if there are any non numeric strings as coordinates.
			if(curr == 'x'){
				xfound = true
			} else if(curr == 'y'){
				yfound = true
			} else {
				console.warn('unexpected value ' + curr + ', polygon points should only have x and y')
			}

			if(typeof unsorted_list[i][curr] == 'string')
			{
				if(!isNaN(unsorted_list[i][curr])){
					unsorted_list[i][curr] = parseFloat(unsorted_list[i][curr])
				} else { 
					console.warn("A non numeric string value was passed as a coordinate for a polygon")
					return [];					
				}	
			}			
		}
		if(!xfound || !yfound){
			let missing_coords = "";
			if(!xfound){
				missing_coords = "x";
				if(!yfound)
					missing_coords = "x and y";
			} else missing_coords = "y";
			console.warn("error, missing value for " + missing_coords + " in point " + i + " in input polygon");
			return [];
		}
	}
		let leftmost = unsorted_list[0];
		let leftmostindex = 0;
		let rightmost = unsorted_list[0];
		let rightmostindex = 0;
		//determine the left and rightmost points. In case of ties, choose top left and bottom right.
		for(let i = 0; i < unsorted_list.length; i++)
		{
			if(unsorted_list[i].x < leftmost.x || (unsorted_list[i].x == leftmost.x && unsorted_list[i].y > leftmost.y)) {
				leftmost = unsorted_list[i];
				leftmostindex = i;
			} 
			if(unsorted_list[i].x > rightmost.x || (unsorted_list[i].x == rightmost.x && unsorted_list[i].y < rightmost.y)) {
				rightmost = unsorted_list[i];
				rightmostindex = i;
			}
		}

		let sorted_user_points = unsorted_list.slice(0);
		sorted_user_points.splice(leftmostindex, 1);
		if(rightmostindex > leftmostindex)
			rightmostindex--;
		sorted_user_points.splice(rightmostindex, 1);
		let A = [];
		let B = [];
		
		let slope = leftmost.x != rightmost.x ?  (rightmost.y - leftmost.y) / (rightmost.x - leftmost.x) : 1337
		for(let i = 0; i < sorted_user_points.length; i++) {
			if(sorted_user_points[i].y >= (leftmost.y + (sorted_user_points[i].x - leftmost.x) * slope)) {
				A.push(sorted_user_points[i]);
			} else {
				B.push(sorted_user_points[i]);
			}
		}

		A.sort(sortLeftToRight)
		B.sort(sortLeftToRight)
		B.reverse()
		sorted_user_points = [leftmost];
		sorted_user_points = sorted_user_points.concat(A);
		sorted_user_points.push(rightmost);
		sorted_user_points = sorted_user_points.concat(B);
		return sorted_user_points;
}

// given 3 points of form {x: ,y; }, a, b and new_point, returns true if new_point is intersecting the line segment ab
function Intersecting(a, b, new_point, margin_of_error = 0.75) {
	//special case for vertical lines
	if(a.x == b.x)
	{
		if(Math.abs(new_point.x - a.x) <= margin_of_error && ((new_point.y < a.y) != (new_point.y < b.y)))
		{
			return true;
		}
		return false;
	}

	let slope = (b.y - a.y) / (b.x - a.x);
	let expected_y = (new_point.x - a.x) * slope + a.y;
	let normal_angle = Math.atan(slope)
	let margin_y_component = Math.max(Math.abs(Math.sin(normal_angle) * margin_of_error), 0.001);
	if((Math.abs(expected_y - new_point.y)) <= margin_y_component && ((new_point.x < a.x) != (new_point.x < b.x)))
	{
		return true;
	}
	return false;		
}

// takes a polygon in the form of an array of points {x: , y: }. Removes any redundant points
// these include intersecting points, and 'extending' line segments, that is {A, B, C} such that ABC forms a straight line.
export function CleanPolygon(input_list) {
	for(let i = 0; i < input_list.length; i++) {
		let curr = input_list[i];
		//check if the current point is in between the previous and next points
		if(Intersecting(input_list[(i-1 + input_list.length) % input_list.length], input_list[(i+1) % input_list.length], curr))
		{
			input_list.splice(i, 1);
			i--;
			continue;
		}
		//check if the current point is within each line segment
		for(let j = 0; j < input_list.length; j++) {
			let a = input_list[j];
			let b = input_list[(j + 1) % input_list.length];
			//make sure not to check line segments containing the current point!
			if(a != curr && b != curr && Intersecting(a, b, curr)){
				input_list.splice(i, 1);
				i--;
				continue;
			}
		}
	}
	return input_list;
}

// Takes an array of {x: , y: } points, assumed to already be a simple polygon. Returns a new array with the point inserted
// Check if new_value is intersecting any line segments, and if so simply shrink the side of the line segment closest to the new point
// Check if new_value continues its adjacent line segments, and if so, remove the newly interior point from that line segment. 
// Note that it cannot check if the new point is extending EVERY line segment, otherwise it will run into the problem of a new point
// Deleting a point somewhere completely else on the polygon. So only check the adjacent ones.
export function InsertPointIntoPolygon(input_list, new_value, simple_polygon){

	let modified_list = input_list.slice(0);
	if(isNaN(new_value.x) || isNaN(new_value.y)) {
		console.warn('new_value missing x or y')
		return {};
	}

	for(let i = 0; i < input_list.length; i++) {
		let curr = input_list[i];
		let next = input_list[(i + 1) % input_list.length];
		// if new value is within any line segment, remove the closer of the two points in the line segment
		if(Intersecting(curr, next, new_value)) {
			let closer_to_curr = new_value.x == curr.x ? Math.abs(new_value.y - curr.y) > Math.abs(new_value.y - next.y) : Math.abs(new_value.x - curr.x) > Math.abs(new_value.x - next.x)
			if(closer_to_curr) {
				modified_list.splice((i + 1) % input_list.length, 1);
				modified_list.push(new_value);
				return modified_list;
			}
			else {
				modified_list.splice(i, 1);
				modified_list.push(new_value)
				return modified_list;
			}
		}
	}

	modified_list.push(new_value);

	if(simple_polygon)
	{
		modified_list = SimplePolygon(modified_list);
	}
	//otherwise, insert the value, and check for any points that may be newly intersecting, or any points which 'extend' a line segment
	//that is, for points [A, B, C] , B is within AC. NOT the same as intersection, because AC isn't actually a line segment.
	modified_list = CleanPolygon(modified_list);
	return modified_list;

	
}